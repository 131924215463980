import ResourceService from "./resource_service.js";
const { countBy, random } = require("lodash");

export default class MapService extends ResourceService {
  index(minLat, maxLat, minLng, maxLng) {
    return this.axios
      .get(this.slug, {
        params: { minLat, maxLat, minLng, maxLng },
      })
      .then((res) => {
        res.data.set = varySimilarLocs(res.data.set);
        return res.data;
      })
      .catch(this.parseError);
  }
}

function varySimilarLocs(results) {
  let dupLocs = [];
  results.forEach((result) => {
    let { lat, lng } = result.location;
    dupLocs.push(minifyLoc(lat, lng));
  });

  dupLocs = Object.entries(countBy(dupLocs))
    .reduce((acc, [key, val]) => {
      return val > 1 ? acc.concat(key) : acc;
    }, [])
    .map(String);

  results.forEach((result) => {
    let { lat, lng } = result.location;
    if (dupLocs.includes(minifyLoc(lat, lng))) {
      const upLat = lat + 0.0005;
      const downLat = lat - 0.0005;
      const upLng = lng + 0.0005;
      const downLng = lng - 0.0005;
      result.location.lat = random(upLat, downLat);
      result.location.lng = random(upLng, downLng);
    }
  });
  return results;
}

function minifyLoc(lat, lng) {
  return `${lat},${lng}`;
}
