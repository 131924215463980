import MainService from "./main_service";
import store from "@/store/index.js";

export default class AuthService extends MainService {
  constructor(slug) {
    super(slug, "auth");
    return this;
  }

  create(formData) {
    return this.axios
      .post(this.baseURL, formData)
      .then((res) => {
        if (res.status == 200) {
          return `Password has been set for ${formData.email}! Activate through email.`;
        }
      })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == 401) {
          throw "Employee Email not Found !";
        } else if (status == 403) {
          throw "Employee already has password set ! Resending Activation Link ... !";
        } else {
          throw `${err}`;
        }
      });
  }

  read(email, password) {
    return this.axios
      .post(`sign_in`, { email, password })
      .then((res) => {
        if (res.status == 200) {
          const { token, id } = res.data;
          localStorage.setItem("userToken", token);
          localStorage.setItem("userId", id);
          store.dispatch("setUserToken", token);
          store.dispatch("setUserId", id);
          return "Successfully Logged In!";
        }
      })
      .catch((err) => {
        console.error(err);
        const status = err?.response?.status;
        if (status == 401) {
          throw "Invalid or Missing Credentials";
        } else if (status == 403) {
          throw "Unauthorized Access";
        } else {
          console.log("auth_service.js");
          throw `General Error : ${
            !status ? "Backend Server is Down!" : status
          } `;
        }
      });
  }

  delete() {
    return this.axios
      .post("/sign_out")
      .then(() => {
        this.setUserToken(null);
      })
      .catch((err) => {
        return `Sign Out Failed - ${err}`;
      });
  }

  update(activationCode) {
    console.log("auth_service.js: update");
    return this.axios
      .patch(this.baseURL, {
        activation: activationCode,
      })
      .then((res) => {
        if (res.status == 200) {
          return "Activation Successful! Kindly Login.";
        }
      })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == 404) {
          return "Activation Code Invalid";
        } else if (status == 500) {
          return "Internal Server Error";
        } else {
          return `${status}`;
        }
      });
  }
}
