<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div class="columns is-centered">
        <AuthTitle />
        <div class="column is-narrow pl-4" align="left">
          <form @submit.prevent="attemptLogin()">
            <input
              class="input mb-2"
              v-model="email"
              placeholder="Email"
              type="email"
              :size="8"
              maxlength="32"
            />
            <input
              class="input mb-2"
              v-model="password"
              type="password"
              placeholder="Password"
              :size="8"
              maxlength="32"
            />
            <div class="level">
              <AuthFooter />
              <button
                class="level-item button is-success"
                @click.prevent="attemptLogin"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import AuthFooter from "@/views/auth/AuthFooter.vue";
import AuthTitle from "@/views/auth/AuthTitle.vue";
import AuthService from "@/services/auth_service.js";
import { useRouter, useRoute } from "vue-router";

import { ref } from "vue";
export default {
  props: {},
  components: { AuthTitle, AuthFooter },
  setup() {
    const store = useStore();
    const email = ref(null);
    const password = ref(null);
    const router = useRouter();
    const { query } = useRoute();
    const activationCode = query?.activation;
    console.log({ activationCode });

    const authService = new AuthService();

    if (activationCode) activateUser(activationCode);

    function activateUser(activationCode) {
      console.log("activating user ...");
      authService
        .update(activationCode)
        .then((msg) => {
          store.commit("noteSuccess", msg);
        })
        .catch((err) => {
          store.commit("noteError", err);
        });
    }

    function attemptLogin() {
      console.log("attempting login ...");
      authService
        .read(email.value, password.value)
        .then((msg) => {
          store.commit("noteSuccess", msg);
          router.push({ name: "Home" });
        })
        .catch((err) => {
          store.commit("noteError", err);
        });
    }

    return { email, password, activateUser, attemptLogin };
  },
};
</script>
