<template>
  <router-link :to="path">
    <div class="level module-title mr-6">
      <div class="level-left">
        <div class="level-item is-justify-content-flex-end mr-4">
          <span class="material-icons md-72" data-test-id="logo">
            {{ logo }}
          </span>
        </div>
        <div class="level-item is-justify-content-flex-start">
          <span>
            <h1 class="title mb-5" align="left" data-test-id="title">
              {{ title }}
            </h1>
            <h2 class="subtitle" align="left" data-test-id="subtitle">
              {{ subtitle }}
            </h2>
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  setup() {
    const { meta, path } = useRoute();
    return { ...meta, path };
  },
};
</script>

<style lang="sass" scoped>
.module-title
  color: black
</style>
