<template>
  <section class="section">
    <h1 class="has-text-left is-size-1 header-legal">
      Legal Document(s) and Certifications
    </h1>
    <h2 class="is-medium has-text-left">
      To see the original copy of our legal documents and certifications email
      us at
      <a href="mailto:projects@rec.engineering">
        <span class="header is-size-6">projects@rec.engineering</span>
      </a>
    </h2>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/bir_certficate.png"
                alt="bir_certficate.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">BIR Certficate</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/barangay_clearance.png"
                alt="barangay_clearance.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">
                  Barangay Business Clearance
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/environment_clearance.png"
                alt="environment_clearance.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">
                  Municipal Environment Clearance
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/fire_safety_cert.png"
                alt="fire_safety_cert.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">
                  Fire Safety Inspection Certificate
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/mayor_permit.png"
                alt="mayor_permit.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">Mayor’s Permit</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img src="@/assets/legal_docu/pcab.png" alt="pcab.png" />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">
                  Philippine Contractor Accreditation Board(PCAB)
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img
                src="@/assets/legal_docu/sanitary_permit.png"
                alt="sanitary_permit.png"
              />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">
                  Sanitary Permit To Operate
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img src="@/assets/legal_docu/sec_reg.png" alt="sec_reg.png" />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">SEC Registration</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img src="@/assets/legal_docu/sec_reg1.png" alt="sec_reg.png" />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">SEC Registration</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <figure>
              <img src="@/assets/legal_docu/urr_reg.png" alt="sec_reg.png" />
              <figcaption class="content">
                <p class="is-size-6 has-text-weight-bold">URR Registration</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="sass" scoped>
.header
  color:#F7931E

section
  height: 100vh
  margin-top:10rem

.sub-content
  margin-left:2rem
  width: 30rem
.header-service
  margin-bottom: 5rem
  color:#F7931E



.header-legal
 color:#F7931E
.sub-header-legal
 margin-bottom: 3rem

@media print
  .no-print, .no-print *
    display: none !important

@media (max-width: 1023px)

@media (max-width: 768px)
  section
    margin-top: 150rem
  .img
    margin-top: 5rem
  .card
   width:15rem
   left:3rem
  .is-size-1
    font-size: 2rem !important
    margin-bottom:1rem
</style>
