<template>
  <div class="column business-card-cntr"  data-test-id="business-card-img">
    <div>Business Card (2" x 3.5") - Front</div>
    <div class="business-card-cntr__card-front m-2">
      <div class="columns">
        <div class="column">
          <div
            class="is-size-2 m-1 ml-4"
            align="left"
            
          >
            {{ employee.name }}
          </div>
          <div class="is-size-5 mt-1 m-1 ml-4" align="left" >
            {{ employee.position }}
          </div>

          <div class="is-size-6 m-1 ml-4">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <i class="material-icons">phone_in_talk</i>
                </div>
                <div class="level-item">
                  {{ employee.contactNumbers }}
                </div>
              </div>
            </div>
          </div>

          <div class="is-size-6 m-1 ml-4">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <i class="material-icons">email</i>
                </div>
                <div class="level-item">projects@rec.engineering</div>
              </div>
            </div>
          </div>

          <div class="is-size-6 m-1 ml-4">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <i class="material-icons">language</i>
                </div>
                <div class="level-item">rec.engineering</div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <br />
          <div
            class="level mb-2"
            v-for="serviceOffered in servicesOffered"
            :key="serviceOffered._id"
          >
            <div class="level-left" data-test-id="service-offered">
              <i class="material-icons is-size-5 level-item">
                {{ serviceOffered.materialIcon }}
              </i>
              <span class="is-size-6 level-item">{{
                serviceOffered.label
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>Business Card (2" x 3.5") - Back</div>
    <div class="business-card-cntr__card-back m-2">
      <div class="level">
        <div class="level-item has-text-centered mt-6">
          <img
            class="m-4 business-card-cntr__back-logo"
            src="@/assets/rec_logo_stencil.svg"
            data-test-id="businessName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceService from "@/services/resource_service";
import { ref, onMounted } from "vue";
export default {
  props: { employee: Object },
  setup() {
    const serviceOffers = new ResourceService("service_offers");
    const servicesOffered = ref([]);

    onMounted(loadServiceOffers);

    function loadServiceOffers() {
      serviceOffers.index().then((res) => {
        servicesOffered.value = res?.set;
      });
    }

    return { servicesOffered };
  },
};
</script>

<style lang="sass" scoped>
@mixin printable-card-details
  border: solid thin black
  overflow: hidden

@mixin landscape-dimensions
  width: 35em
  height: 20em

.business-card-cntr

  &__card-front
    @include printable-card-details
    @include landscape-dimensions

  &__card-back
    @include printable-card-details
    @include landscape-dimensions
    background-color: #FF9933

  &__back-logo
    height: 9em
    margin-top: 5em
</style>
