<template>
  <LandingPage />
  <div class="container">
    <ServiceOffered />
    <MissionVision />
    <CoreValues />
    <Project />
    <LegalDocuments id="contentToPrint" />
    <ContactInfo />
  </div>
  <FooterInfo :year="year" />
</template>

<script>
import FrontPageService from "@/services/frontpage_service.js";
import { onMounted, ref } from "vue";
import { imgSRC, COMPANY_ADDRESS } from "@/global.js";
import LandingPage from "../components/home_sections/LandingPage.vue";
import ServiceOffered from "../components/home_sections/ServiceOffered.vue";
import MissionVision from "../components/home_sections/MissionVision.vue";
import CoreValues from "../components/home_sections/CoreValues.vue";
import Project from "../components/home_sections/Project.vue";
import LegalDocuments from "../components/home_sections/LegalDocuments.vue";
import ContactInfo from "../components/home_sections/ContactInfo.vue";
import FooterInfo from "../components/home_sections/FooterInfo.vue";

const { DateTime } = require("luxon");
export default {
  setup() {
    const services = ref([]);
    const feats = ref([]);
    const projStats = ref({ proposals: 0, wips: 0, finished: 0 });
    const frontPageService = new FrontPageService();
    const year = DateTime.now().toFormat("yyyy");
    const projList = ref([]);

    onMounted(async () => {
      const { serviceOffers, stats, featured, listed } =
        await frontPageService.index();
      services.value = serviceOffers;
      projStats.value = stats;
      feats.value = featured;
      projList.value = listed;
    });
    return {
      projList,
      services,
      feats,
      projStats,
      imgSRC,
      COMPANY_ADDRESS,
      year,
    };
  },
  components: {
    LandingPage,
    ServiceOffered,
    MissionVision,
    CoreValues,
    Project,
    LegalDocuments,
    ContactInfo,
    FooterInfo,
  },
};
</script>
