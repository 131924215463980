<template>
  <div class="container">
    <div class="level mt-4">
      <ModuleTitle />
      <BaseDocument title="funds" v-model="fundID" />
    </div>
    <table
      class="table is-narrow is-hoverable is-striped"
      align="center"
      :key="refreshFlag"
    >
      <thead>
        <tr align="left">
          <th>Date</th>
          <th>Type</th>
          <th>Reference Number</th>
          <th>Remark</th>
          <th>Debit</th>
          <th>Credit</th>
          <th>Amount</th>
          <th>AXN</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(fund, index) in entries" :key="index" align="left">
          <td>{{ convertDateTime(fund.date) }}</td>
          <td>{{ fund.type }}</td>
          <td>{{ fund.referenceNumber }}</td>
          <td>{{ fund.remark }}</td>
          <td class="num-col" align="right">
            {{ formatMoney(fund.debit) }}
          </td>
          <td class="num-col" align="right">
            {{ formatMoney(fund.credit) }}
          </td>
          <td class="num-col" align="right">
            {{ formatMoney(fund.accumlatedAMT) }}
          </td>
          <div>
            <router-link
              v-if="fund.type == 'Fund Adjustment'"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              title="`Edit"
              :to="{
                name: `Edit Fund Adjustment`,
                params: { id: fund._id },
              }"
            >
              edit
            </router-link>
            <router-link
              v-else-if="fund.type == 'Share Withdrawal'"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              title="`Edit"
              :to="{
                name: `Edit Share Withdrawal`,
                params: { id: fund._id },
              }"
            >
              edit
            </router-link>
            <router-link
              v-else-if="fund.type == `Share Declaration`"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              :to="{
                name: `Project Initialization`,
                params: { id: fund.projectID },
              }"
            >
              edit
            </router-link>
          </div>
        </tr>
      </tbody>
    </table>
    <p class="is-size-7" align="center">
      * Project Shares counted on Project Acceptance Only
    </p>
    <div class="mt-2" align="center" v-if="fundID">
      <router-link
        :to="{
          name: `New Fund Adjustment`,
          query: { id: fundID },
        }"
        class="button mr-2"
        >New Fund Adjustment</router-link
      >
      <router-link
        :to="{
          name: `New Share Withdrawal`,
          query: { id: fundID },
        }"
        class="button"
        >New Share Withdrawal</router-link
      >
    </div>
  </div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle.vue";
import BaseDocument from "@/components/base_fields/BaseDocument.vue";
import ResourceService from "@/services/resource_service.js";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { convertDateTime, accounting } from "@/global.js";
export default {
  components: {
    BaseDocument,
    ModuleTitle,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const entries = ref([]);
    const fundID = ref(route.query.fund_id);
    const fndSvc = new ResourceService("funds");
    const refreshFlag = ref(Date.now());
    const { formatMoney } = accounting;

    onMounted(async () => {
      if (fundID.value) await refreshEntries(fundID.value);
    });

    watch(fundID, refreshEntries);

    async function refreshEntries(id) {
      entries.value = await fndSvc.read(id, {
        mode: "entries",
      });
      router.push({ query: { fund_id: id } });
      refreshFlag.value = Date.now();
    }

    return {
      fundID,
      entries,
      refreshFlag,
      convertDateTime,
      formatMoney,
    };
  },
};
</script>
