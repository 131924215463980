<template>
  <div class="container pb-6">
    <div class="section p-0">
      <br />
      <div class="level">
        <div class="level-left"><ModuleTitle /></div>
        <div class="level-right">
          <div class="level-item">
            <div class="columns">
              <div class="column">
                <BaseInput
                  v-model="expiration"
                  type="date"
                  title="Requested On"
                  :required="true"
                  data-test-id="expDate"
                />
              </div>
              <div class="column">
                <BaseDocument
                  v-model="employeeField"
                  fieldName="name"
                  title="Employee"
                  :isEnabled="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="section pt-1 pb-6">
        <div class="columns" v-if="employee._id" :key="employee">
          <EmployeeMediaID :employee="employee" :expDate="expiration" />
          <EmployeeMediaCard :employee="employee" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle.vue";
import BaseDocument from "@/components/base_fields/BaseDocument.vue";
import ResourceService from "@/services/resource_service";
import EmployeeMediaID from "./EmployeeMediaID.vue";
import EmployeeMediaCard from "./EmployeeMediaCard.vue";
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import BaseInput from "../../components/base_fields/BaseInput.vue";

export default {
  components: {
    ModuleTitle,
    BaseDocument,
    EmployeeMediaID,
    EmployeeMediaCard,
    BaseInput,
  },
  setup() {
    const route = useRoute();
    const routeID = route.params.id;
    const employeeField = ref(null);
    const expiration = ref(null);

    assessRoute();
    onMounted(() => fetchEmployee(routeID));
    watch(employeeField, (newID) => fetchEmployee(newID));

    function assessRoute() {
      if (routeID) employeeField.value = routeID;
    }

    const employee = ref({});
    const employeeService = new ResourceService("employees");
    function fetchEmployee(employeeID) {
      if (employeeID) {
        employeeService.read(employeeID).then((employeeData) => {
          const url = `/enterprise/employee_media/${employeeID}`;
          history.pushState({}, null, url);
          employee.value = employeeData;
        });
      }
    }

    return {
      employeeField,
      fetchEmployee,
      employee,
      expiration,
    };
  },
};
</script>
