import MainService from "./main_service";

export default class PasswordService extends MainService {
  constructor(slug) {
    super(slug, `auth/password`);
    return this;
  }

  // recover passsword
  create(email) {
    return this.axios.post(this.slug, { email }).catch((err) => {
      const status = err?.response?.status;
      if (status == "404") {
        throw "No Email Found";
      } else {
        throw "Server Error";
      }
    });
  }

  // change password
  update(email, oldPassword, newPassword) {
    return this.axios
      .patch(this.slug, { email, oldPassword, newPassword })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == "404") {
          throw "No Email Found";
        } else {
          throw "Server Error";
        }
      });
  }
}
