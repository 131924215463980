<template>
  <transition name="fade">
    <div v-if="note" align="center" class="notification-bar m-0 p-0">
      <div class="notification m-0 p-0" :class="noteClass">
        {{ note }}
      </div>
    </div>
  </transition>
</template>

<script>
import { watch, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const note = ref(null);
    const noteClass = ref(null);

    let timeToLive = 3000;

    watch(
      () => store.getters.note,
      (noteMsg) => {
        timeToLive += 3000;
        note.value = noteMsg;
        noteClass.value = store.getters.noteClass;
        setTimeout(() => {
          store.commit("resetNote");
          timeToLive = 3000;
        }, timeToLive);
      }
    );

    return { note, noteClass };
  },
};
</script>

<style lang="sass" scoped>
.notification-bar,
.notification
  border-radius: 0

.notification-bar
  // position: relative
  // width: 100%
  // top: 50em
  // z-index: 25

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s ease

.fade-enter-from,
.fade-leave-to
  opacity: 0
</style>
