<template>
  <div class="container">
    <div class="level mt-4">
      <ModuleTitle />
      <BaseDocument title="Employee" v-model="employeeID" />
    </div>
    <table
      class="table is-narrow is-hoverable is-striped"
      align="center"
      :key="refreshFlag"
    >
      <thead>
        <tr align="left">
          <th>Date</th>
          <th>Type</th>
          <th>REFNO</th>
          <th>Remark</th>
          <th>Debit</th>
          <th>Credit</th>
          <th>Amount</th>
          <th>AXN</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dividend in dividends" :key="dividend" align="left">
          <td>{{ convertDateTime(dividend.date) }}</td>
          <td>{{ dividend.type }}</td>
          <td>{{ dividend.referenceNumber }}</td>
          <td>{{ dividend.remark }}</td>
          <td class="num-col" align="right">
            {{ formatMoney(dividend.debit) }}
          </td>
          <td class="num-col" align="right">
            {{ formatMoney(dividend.credit) }}
          </td>
          <td class="num-col" align="right">
            {{ formatMoney(dividend.accumlatedAMT) }}
          </td>
          <td>
            <router-link
              v-if="dividend.type == `Dividend Adjustment`"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              :to="{
                name: `Edit Dividend Adjustment`,
                params: { id: dividend._id },
              }"
            >
              edit
            </router-link>
            <router-link
              v-else-if="dividend.type == `Share Withdrawal`"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              :to="{
                name: `Edit Share Withdrawal`,
                params: { id: dividend._id },
              }"
            >
              edit
            </router-link>
            <router-link
              v-else-if="dividend.type == `Share Declaration`"
              class="material-icons is-small button"
              data-test-id="`edit-btn`"
              :to="{
                name: `Project Initialization`,
                params: { id: dividend.projectID },
              }"
            >
              edit
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="is-size-7" align="center">
      * Project Shares counted on Project Acceptance Only
    </p>
    <div class="mt-2" align="center" v-if="employeeID">
      <router-link
        :to="{
          name: `New Dividend Adjustment`,
          query: { employee: employeeID },
        }"
        class="button mr-2"
        >New Dividend Adjustment</router-link
      >
      <router-link
        :to="{
          name: `New Share Withdrawal`,
          query: { employee_id: employeeID },
        }"
        class="button"
        >New Share Withdrawal</router-link
      >
    </div>
  </div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle.vue";
import BaseDocument from "@/components/base_fields/BaseDocument.vue";
import ResourceService from "@/services/resource_service.js";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { convertDateTime, accounting } from "@/global.js";
export default {
  components: { ModuleTitle, BaseDocument },
  setup(props) {
    props;
    const route = useRoute();
    const router = useRouter();
    const dividends = ref([]);
    const employeeID = ref(route.query.employee_id);
    const empSvc = new ResourceService("employees");
    const refreshFlag = ref(Date.now());
    const { formatMoney } = accounting;
    const sum = ref(0);

    onMounted(async () => {
      if (employeeID.value) await refreshDividends(employeeID.value);
    });

    watch(employeeID, refreshDividends);

    function calcAMT({ debit, credit }) {
      sum.value += debit - credit;
      return sum.value;
    }

    async function refreshDividends(empID) {
      dividends.value = await empSvc.read(empID, {
        mode: "dividends",
      });
      router.push({ query: { employee_id: empID } });
      refreshFlag.value = Date.now();
    }

    return {
      calcAMT,
      employeeID,
      dividends,
      refreshFlag,
      convertDateTime,
      formatMoney,
      sum,
    };
  },
};
</script>

<style lang="sass" scoped>

.num-col
  white-space: nowrap
</style>
