import { watch, onMounted, toRefs } from "vue";
import InputHandler from "@/components/base_fields/input_handler.js";

class DocumentHandler extends InputHandler {
  handleIncomingField() {
    const { modelValue } = toRefs(this.props);

    onMounted(() => this.syncVals(this.fieldValue, modelValue.value));
    watch(modelValue, (newMV) => this.syncVals(this.fieldValue, newMV));
  }

  syncVals(fieldVal, modelVal) {
    fieldVal.value = modelVal?._id ? modelVal._id : modelVal;
  }
}

export default DocumentHandler;
