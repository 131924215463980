import MainService from "./main_service.js";
import { DateTime } from "luxon";
class AuditReportService extends MainService {
  constructor() {
    super(
      "audit_report",
      `${process.env.VUE_APP_BACKEND_HOST}/resource/audit_report`
    );
    return this;
  }

  index(
    startDate = DateTime.local().plus({ months: -1 }),
    endDate = DateTime.local().plus({ months: 1 }),
    referenceNumber = "",
    type = ""
  ) {
    return this.axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/resource/audit_report`, {
        params: { startDate, endDate, referenceNumber, type },
      })
      .then((res) => {
        return res;
      })
      .catch(this.parseError);
  }

  create(formData) {
    return this.axios
      .post(
        `${process.env.VUE_APP_BACKEND_HOST}/resource/audit_report`,
        formData
      )
      .then((res) => {
        if (res.status == 200) {
          return {
            link: `${process.env.VUE_APP_BACKEND_HOST}/uploads/${res?.data}`,
            filename: res?.data,
          };
        }
      })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == 401) {
          throw "authorized";
        } else if (status == 403) {
          throw "zip has already ";
        } else {
          throw `${err}`;
        }
      });
  }
  delete(filename) {
    return this.axios
      .post(
        `${process.env.VUE_APP_BACKEND_HOST}/resource/audit_report/delete`,
        filename
      )
      .then((res) => {
        if (res.status == 200) {
          return `Deleting ${filename} sucesss`;
        }
      })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == 401) {
          throw "authorized !";
        } else if (status == 404) {
          throw `${filename} not found`;
        } else {
          throw `${err}`;
        }
      });
  }
}

export const auditReportService = new AuditReportService();
