<template>
  <div class="level mb-2">
    <div class="level-left">
      <Multiselect
        :key="refreshFlag"
        v-model="fieldValue"
        v-bind="$attrs"
        ref="multiselect"
        class="level-item mr-0"
        :options="async (query) => await setOptions(query)"
        :disabled="isDisabled(isEnabled)"
        :placeholder="`${title}`"
        :style="`width: ${width}em`"
        :id="uniqueID"
        :min-chars="3"
        :delay="0"
        :searchable="true"
        :class="isAddable ? `` : `multiselect-close`"
      />
      <div class="is-hidden">
        {{ fieldValue }}
      </div>
      <div
        class="level-item is-justify-content-left"
        v-if="isAddable && !isDisabled(isEnabled)"
      >
        <button
          class="button material-icons document-button"
          @click.prevent="isPopupOpen = !isPopupOpen"
        >
          add
        </button>
      </div>
      <input
        class="level-item tester"
        :data-test-id="uniqueID"
        @change="overrideID"
        v-if="isDEVENV"
      />
    </div>
  </div>
  <PopupForm
    v-if="isPopupOpen"
    :title="title"
    :filename="filename"
    :slug="slug"
    :status="isPopupOpen"
    @submitted="loadNewDocument"
    @closePopup="isPopupOpen = false"
    :data-test-id="`${dataTestId}Form`"
  />
</template>

<script>
import { pascalCase, snakeCase, paramCase } from "change-case";
import { ref, toRefs, onMounted, computed } from "vue";
import { defaultProps } from "./concerns.js";
import pluralize from "pluralize";
import DocumentHandler from "./document_handler.js";
import SelectableService from "@/services/selectable_service.js";
import ResourceService from "@/services/resource_service.js";
import Multiselect from "@vueform/multiselect";
import PopupForm from "@/components/form_layouts/PopupForm.vue";

export default {
  props: {
    ...defaultProps,
    title: { type: String, required: true },
    fieldName: { type: String, default: "_REP" },
    filename: { type: String, default: (props) => pascalCase(props.title) },
    slug: {
      type: String,
      default: (props) => snakeCase(pluralize(props.title)),
    },
    width: { type: Number, default: 16 },
    isEnabled: { type: Boolean },
    isAddable: { type: Boolean, default: true },
    dataTestId: { type: String, default: (props) => paramCase(props.title) },
  },
  components: { Multiselect, PopupForm },
  setup(props, { emit }) {
    const multiselect = ref(null);
    const isPopupOpen = ref(false);
    const documentHandler = new DocumentHandler(props, emit);
    const { uniqueID, isDisabled, fieldValue } = documentHandler.exec();
    const { slug, fieldName, modelValue } = toRefs(props);
    const selected = { label: null, value: null };
    const refreshFlag = ref(Date.now());
    selected.value = modelValue?.value?._id;
    if (selected.value) selected.label = modelValue?.value[fieldName.value];

    onMounted(async () => {
      if (
        typeof modelValue?.value == "string" &&
        !selected.value &&
        !selected.label
      ) {
        const slgVal = slug.value;
        const fldVal = fieldName.value;
        const fieldService = new SelectableService(slgVal, fldVal);
        const doc = await fieldService.read(modelValue.value);
        selected.label = doc.label;
        selected.value = doc._id;
        if (multiselect.value) {
          multiselect.value.refreshOptions();
          multiselect.value.select(selected);
        }
      }
      refreshFlag.value = Date.now();
    });

    async function loadNewDocument(doc) {
      selected.label = doc[fieldName.value];
      selected.value = doc._id;
      multiselect.value.refreshOptions();
      multiselect.value.select(selected);
    }

    async function setOptions(query = null) {
      const slgVal = slug.value;
      const fldVal = fieldName.value;
      const fieldService = new SelectableService(slgVal, fldVal);
      const resourceService = new ResourceService(slgVal);
      if (query) {
        const optionSet = await fieldService.index(query);
        return formatOptions(optionSet);
      } else {
        if (selected.value) {
          const resource = await resourceService.read(selected.value);
          selected.label = resource[fldVal];
          return [selected];
        } else {
          const optionSet = await fieldService.index(query);
          return formatOptions(optionSet);
        }
      }
    }

    function formatOptions(optionSet) {
      return optionSet.map((option) => {
        return { value: option._id, label: option.label };
      });
    }

    async function overrideID(event) {
      const hiddenService = new ResourceService(slug.value);
      try {
        const doc = await hiddenService.read(event.target.value);
        selected.label = doc[props.fieldName];
        selected.value = doc._id;
        multiselect.value.refreshOptions();
        multiselect.value.select(selected);
      } catch (err) {
        console.log({ err });
      }
    }

    const isDEVENV = computed(() => {
      return process.env.VUE_APP_ENVIRONMENT == "DEVELOPMENT";
    });

    return {
      isDEVENV,
      overrideID,
      uniqueID,
      isDisabled,
      fieldValue,
      PopupForm,
      isPopupOpen,
      setOptions,
      multiselect,
      loadNewDocument,
      refreshFlag,
    };
  },
};
</script>

<style scoped lang="sass">
:root
  --ms-max-height: 200em

.multiselect
  margin: 0
  border-radius: 4px 0px 0px 4px
  border: thin rgb(219, 219, 219) solid
  width: 100%
  background-color: whitesmoke
  border-right: 0

.multiselect.is-disabled
  background-color: whitesmoke
  border: none
  color: #7a7a7a

.multiselect-dropdown
  position: absolute
  z-index: 50
  height: 200em

.multiselect-close
  margin: 0
  border-radius: 0px 0px 0px 0px
  border: thin rgb(219, 219, 219) solid
  width: 100%
  background-color: whitesmoke

.document-button
  border-radius: 0px 4px 4px 0px

.tester
  position: absolute
  z-index: 4
  opacity: 20%
  border: 0
</style>
