import MainService from "./main_service.js";
import { serialize } from "object-to-formdata";

export default class ResourceService extends MainService {
  constructor(slug) {
    super(slug, "resource");
    return this;
  }

  index(
    params = {
      qry: "",
      lmt: 10,
      pge: 0,
      srt: "updatedAt",
      ord: "desc",
      bin: false,
    }
  ) {
    return this.axios
      .get(this.slug, {
        params,
      })
      .then((res) => {
        return res.data;
      })
      .catch(this.parseError);
  }

  create(formData) {
    return this.axios
      .post(
        this.slug,
        serialize(formData, { indices: true }),
        this.headerOptions
      )
      .then((res) => {
        return res.data;
      })
      .catch(this.parseError);
  }

  read(id, set = {}) {
    let slg = `${this.slug}/${id}`;
    if (set?.mode) slg = `${slg}?${this.queryize(set)}`;
    return this.axios
      .get(slg)
      .then((res) => {
        return res.data;
      })
      .catch(this.parseError);
  }

  update(id, formData) {
    let slg = `${this.slug}/${id}`;
    let postData = serialize(formData, {
      indices: true,
      allowEmptyArrays: true,
    });
    return this.axios
      .post(slg, postData, this.headerOptions)
      .then((res) => {
        return res.data;
      })
      .catch(this.parseError);
  }

  delete(id, restore = false) {
    return this.axios
      .post(`${this.slug}/${id}/delete`, { restore })
      .then((res) => {
        return res;
      })
      .catch(this.parseError);
  }

  queryize(set) {
    let params = null;
    let keys = Object.keys(set);
    if (keys.length > 0) {
      params = keys.map((k) => `${k}=${set[k]}`).join("&");
    }
    return params;
  }
}
