import MainService from "./main_service";

export default class UniqService extends MainService {
  constructor(slug, fieldName = "_REP") {
    super(slug, "uniq");
    this.fieldName = fieldName;
  }

  index(val, exc = null) {
    return this.axios
      .get(`${this.slug}/${this.fieldName}`, {
        params: { val, exc },
      })
      .then((res) => res.data)
      .catch((err) => {
        this.handleError(err);
      });
  }
}
