import MainService from "./main_service.js";
import { DateTime } from "luxon";
class AuthorizationCenterService extends MainService {
  constructor() {
    super(
      "authorization_center",
      `${process.env.VUE_APP_BACKEND_HOST}/resource/authorization_center`
    );
    return this;
  }

  index(
    startDate = DateTime.local().plus({ months: -1 }),
    endDate = DateTime.local().plus({ months: 1 }),
    referenceNumber = "",
    status = "",
    type = ""
  ) {
    return this.axios
      .get(
        `${process.env.VUE_APP_BACKEND_HOST}/resource/authorization_center`,
        {
          params: { startDate, endDate, referenceNumber, status, type },
        }
      )
      .then((res) => {
        return res;
      })
      .catch(this.parseError);
  }
}

export const authorizationCenterService = new AuthorizationCenterService();
