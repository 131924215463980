<template>
  <router-link :key="title" :to="linkPath" class="navbar-item pr-2">
    <span class="material-icons mr-2">{{ linkLogo }}</span>
    <span>{{ title }}</span>
  </router-link>
</template>

<script>
import { useRouter } from "vue-router";
import { computed } from "vue";
export default {
  props: { title: String },
  setup(props) {
    const router = useRouter();
    const routes = router?.options?.routes;

    const linkPath = computed(() => {
      let routePath = null;
      routes.forEach((route) => {
        if (route.name == props.title) {
          routePath = route?.path;
        }
      });
      return routePath;
    });

    const linkLogo = computed(() => {
      let routeLogo = null;
      routes.forEach((route) => {
        if (route.name == props.title) {
          routeLogo = route?.meta?.logo;
        }
      });
      return routeLogo;
    });

    return { linkPath, linkLogo };
  },
};
</script>
