<template>
  <div class="container pb-6">
    <div class="mt-4">
      <ModuleTitle />
    </div>
    <hr />
    <div class="level">
      <div class="level-left">
        <BaseInput
          class="level-item"
          type="date"
          v-model="fields.startDate"
          :size="20"
          title="Start Date"
        />
        <BaseInput
          class="level-item"
          type="date"
          v-model="fields.endDate"
          title="End Date"
        />
      </div>
      <div class="level-right">
        <input
          type="text"
          v-model="fields.referenceNumber"
          class="input is-normal level-item"
          style="width: 40%"
          data-test-id="reference"
        />
        <select
          v-model="fields.type"
          class="select is-normal level-item"
          style="width: 40%"
          data-test-id="typeSort"
        >
          <option v-for="(choice, index) in typeChoices" :key="index">
            {{ choice }}
          </option>
        </select>
        <select
          v-model="fields.status"
          class="select is-normal level-item"
          style="width: 40%"
          data-test-id="sort"
        >
          <option v-for="(choice, index) in statusChoices" :key="index">
            {{ choice }}
          </option>
        </select>
      </div>
    </div>

    <table
      class="table mt-4 is-fullwidth is-hoverable is-striped"
      v-if="authorizationCenter"
      :key="refreshFlag"
    >
      <thead>
        <tr align="left">
          <th>Date Requested</th>
          <th>Requested By</th>
          <th>Type</th>
          <th>Reference Number</th>
          <th>Status</th>
          <th>Remark</th>
          <th>Cost</th>
          <th colspan="2" style="text-align: center">Action</th>
          <th>Revert</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(liableDoc, index) in authorizationCenter"
          :key="index"
          align="left"
        >
          <td>{{ formatDate(liableDoc.requestedOn) }}</td>
          <td>{{ liableDoc?.requestedBy?.name }}</td>
          <td>{{ capitalCase(liableDoc.type) }}</td>
          <td>{{ liableDoc.referenceNumber }}</td>
          <td>{{ liableDoc.status }}</td>
          <td>{{ liableDoc.remark }}</td>
          <td>
            <p class="block cost">
              {{ formatMoney(liableDoc.amount || 0) }}
            </p>
          </td>
          <AuthorizationCenterOptions
            :liableDoc="liableDoc"
            :index="index"
            :user="user"
            @refresh="refreshTable"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AuthorizationCenterOptions from "./AuthorizationCenterOptions";
import { authorizationCenterService } from "@/services/authorization_center_service";
import { snakeCase, capitalCase } from "change-case";
import ModuleTitle from "@/components/ModuleTitle.vue";
import { onMounted, ref, watch } from "vue";
import BaseInput from "../../components/base_fields/BaseInput.vue";
import ResourceService from "@/services/resource_service";
import store from "@/store/index.js";
import { DateTime } from "luxon";
import { accounting } from "@/global.js";
const { formatMoney } = accounting;
export default {
  components: {
    ModuleTitle,
    BaseInput,
    AuthorizationCenterOptions,
  },
  setup() {
    const user = ref(null);
    const statusChoices = [
      "All",
      "Requested",
      "Assessment Failed",
      "Assessment Passed",
      "Authorized",
      "Unauthorized",
      "Released",
      "Accepted",
    ];
    const typeChoices = [
      "All",
      "Purchase Order",
      "Subcon Payment Slip",
      "Indirect Cost",
      "Project Voucher",
      "Share Withdrawal",
    ];
    const fields = ref({
      startDate: getMonth("before"),
      endDate: getMonth("after"),
      referenceNumber: null,
      status: "All",
      type: "All",
    });
    const authorizationCenter = ref(null);
    const refreshFlag = ref(Date.now());

    onMounted(() => {
      refreshTable();
      getUser();
    });

    watch(
      fields,
      () => {
        refreshTable();
      },
      { deep: true }
    );
    function dateIsValid(date) {
      return date instanceof Date && !isNaN(date);
    }

    async function refreshTable() {
      const { startDate, endDate, referenceNumber, status, type } =
        fields.value;
      if (dateIsValid(new Date(startDate)) && dateIsValid(new Date(endDate))) {
        const { data } = await authorizationCenterService.index(
          startDate,
          endDate,
          referenceNumber,
          status,
          type
        );
        authorizationCenter.value = data;
        refreshFlag.value = Date.now();
      } else {
        return [];
      }
    }

    function formatDate(timestamp) {
      const date = DateTime.fromISO(timestamp);
      return date.toLocaleString(DateTime.DATETIME);
    }

    function getMonth(status) {
      if (status == "before") {
        const date = DateTime.fromISO(DateTime.local().plus({ months: -1 }));
        return date.toString().split("T")[0];
      } else if (status == "after") {
        const date = DateTime.fromISO(DateTime.local());
        return date.toString().split("T")[0];
      }
    }

    async function getUser() {
      await new ResourceService("employees")
        .read(store.getters.userId)
        .then((el) => (user.value = { position: el.position, id: el.id }));
    }

    return {
      refreshFlag,
      capitalCase,
      refreshTable,
      formatMoney,
      user,
      getUser,
      AuthorizationCenterOptions,
      formatDate,
      authorizationCenter,
      snakeCase,
      statusChoices,
      typeChoices,
      fields,
      dateIsValid,
    };
  },
};
</script>
<style scoped>
.cost {
  width: 100px;
}
</style>
