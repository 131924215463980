<template>
  <div class="container" id="print">
    <section>
      <div class="page1">
        <div class="page-content left">
          <img src="@/assets/rec_logo_light.svg" class="title" />
          <img src="@/assets/team.png" class="title" />
          <p class="has-text-left">
            <b class="header">Ronkowski Engineers Co. (REC)</b> was founded in
            order to offer businesses Lean Style and Proactive Building
            Maintenance Design and Services. At our enterprise, we focus on
            reliability centered maintenance; wherein objectives are directed by
            maintaining machinery or asset effectiveness; and empowering our
            teams with multi-skilled maintenance technicians and engineers who
            adhere to the value of 5S and Kaizen Improvement Principles.
          </p>
        </div>

        <div class="page-content right">
          <h1 class="has-text-left is-size-1 header">About Us</h1>
          <div class="columns">
            <div class="column is-half">
              <h1 class="has-text-left is-size-4 content">Mission</h1>
              <p class="has-text-left">
                At Ronkowski Engineers Co., it is our mission to provide the
                highest level of quality services at fair and competitive market
                prices, and maintain the highest level of professionalism,
                honesty, and fairness in our relationships with our customers,
                employees, and vendors. To grow by continuously providing the
                highest quality service, and solutions to markets we already
                serve – and to expand into new areas that build on our
                competencies and customer interests.
              </p>
              <h1 class="has-text-left is-size-4 content">Vission</h1>
              <p class="has-text-left">
                To be the preferred contractor of choice. A company that our
                customers want to work with and our employees are proud to work
                for.
              </p>
            </div>
            <div class="column is-half">
              <h1 class="has-text-left is-size-4 content">Core Values</h1>
              <div class="core-values">
                <p class="has-text-left">
                  As collective group of passionate individuals of trade; and an
                  enterprise which embraces the principles of capitalism and
                  fair trade we value :
                </p>
                <ul class="has-text-left">
                  <li>* Integrity</li>
                  <li>* Honesty</li>
                  <li>* Dedication</li>
                  <li>* Personal Excellence</li>
                  <li>* Continuous Self-Improvement</li>
                  <li>* Mutual Respect</li>
                </ul>
                <br />
                <p class="has-text-left">
                  We hold accountability for our customers, partners, and
                  employees by honoring our commitments, providing results, and
                  striving for the highest quality of service.
                </p>
              </div>
            </div>
          </div>
          <h1 class="has-text-left is-size-1 header">Contact Us</h1>
          <p class="has-text-left">
            Official DTI Registered Name : <b>Ronkowski Engineers Co.</b>
          </p>
          <p class="has-text-left">Brand Name and Trademark : <b>REC</b></p>
          <p class="has-text-left">
            Address:
            <b>
              Great Eastern Resins, L3 & L4 B22, Purok Maligaya 2 ,Barrio
              Mambugan, Antipolo City</b
            >
          </p>
          <p class="has-text-left">
            Contact Numbers :
            <b>09156304970</b>,
            <b>0289427048</b>
          </p>
          <p class="has-text-left">
            Email:
            <b>projects@rec.engineering</b>,
          </p>
        </div>
      </div>
    </section>
    <section>
      <div class="page2">
        <div class="page-content left">
          <h1 class="has-text-left is-size-1 header-service">
            Services Offered
          </h1>
          <div class="container service">
            <div class="columns">
              <div class="column is-half">
                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">power</div>
                    <div>Electrical Systems</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">air</div>
                    <div>HVAC Systems</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">control_camera</div>
                    <div>Testing and Commissioning</div>
                  </div>
                </div>
              </div>
              <div class="column is-half">
                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">
                      fire_extinguisher
                    </div>
                    <div>Fire Protection Systems</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">water_damage</div>
                    <div>Plumbing Systems</div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-content">
                    <div class="card-logo material-icons">design_services</div>
                    <div>HVAC/MEPF Systems Design</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 class="has-text-left is-size-2 header-service">
            Legal Documents and Certifications
          </h1>
          <div class="content is-medium has-text-left">
            To see the original copy of our legal documents and certifications
            email us at
            <a href="mailto:projects@rec.engineering">
              <span class="header is-medium">projects@rec.engineering</span>
            </a>
            <ul class="cert">
              <li>BIR Certficate</li>
              <li>Barangay Business Clearance</li>
              <li>Municipal Environment Clearance</li>
              <li>Sanitary Permit To Operate</li>
              <li>Fire Safety Inspection Certificate</li>
              <li>Mayor’s Permit</li>
              <li>Philippine Contractor Accreditation Board(PCAB)</li>
              <li>SEC Registration</li>
              <li>URR Registration</li>
            </ul>
          </div>
        </div>
        <div class="page-content right">
          <h1 class="has-text-left is-size-1 header-service">
            Featured Projects
          </h1>
          <div class="columns">
            <div class="column">
              <figure>
                <img src="@/assets/project/bbci.jpg" alt="bbci.jpg" />
                <figcaption>
                  <p class="has-text-left is-size-6 has-text-weight-bold">
                    BBCI Office. 18F The Podium West Tower
                  </p>
                  <p class="has-text-left is-size-7">
                    Supply and Installation of Chilled Water FCUs, VAVs.
                  </p>
                </figcaption>
              </figure>
            </div>
            <div class="column">
              <figure>
                <img
                  src="@/assets/project/electrical.jpg"
                  alt="electrical.jpg"
                />
                <figcaption>
                  <p class="has-text-left is-size-6 has-text-weight-bold">
                    Manhattan Plaza Tower 2-Araneta Center Cubao
                  </p>
                  <p class="has-text-left is-size-7">
                    Equipment Startup and Testing
                  </p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <figure>
                <img src="@/assets/project/ust.jpg" alt="ust.jpg" />
                <figcaption>
                  <p class="has-text-left is-size-6 has-text-weight-bold">
                    UST Sta.rosa
                  </p>
                  <p class="has-text-left is-size-7">
                    Start-up of Fans & Blowers
                  </p>
                </figcaption>
              </figure>
            </div>
            <div class="column">
              <figure>
                <img src="@/assets/project/parkway.jpg" alt="parkway.jpg" />
                <figcaption>
                  <p class="has-text-left is-size-6 has-text-weight-bold">
                    8F Office Space, Parkway Corporate Center
                  </p>
                  <p class="has-text-left is-size-7">MVAC Installation</p>
                </figcaption>
              </figure>
            </div>
          </div>
          <div class="other-proj">
            <h1 class="has-text-left is-size-4 header">Other Projects</h1>
            <div>
              <p class="has-text-left is-size-6 has-text-weight-bold">
                19th floor, NEX Tower, 6786 Ayala Ave, Legazpi Village, Makati
              </p>
              <p class="has-text-left is-size-7">Replacement of Float Valves</p>
              <p class="has-text-left is-size-6 has-text-weight-bold">
                UnionBank of the Philippines
              </p>
              <p class="has-text-left is-size-7">
                Equipment Start Up and Testing
              </p>
              <p class="has-text-left is-size-6 has-text-weight-bold">
                1603 Brixton St, Pasig, Metro Manila
              </p>
              <p class="has-text-left is-size-7">
                Change Order for Relocation of Diffuser and EAD, Additional VAV
                Supply and Installation
              </p>
            </div>
            <h1 class="has-text-left is-medium header-service link">
              For more projects visit our website https://rec.engineering or
              facebook page https://www.facebook.com/ronkowskiengineering
            </h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="sass" scoped>
.container
 margin-top: 5rem
.footer-logo
  width: 15rem
  margin-right:40rem
.footer-subtitle
  width:25rem

.social-media
 margin-top: 1rem
 font-size:2.5rem
 color:#4267B2
.service
  width: 40rem
  margin-top: -1.4rem

.level-item
 margin-top: -2rem
.card
  margin-bottom:0.5rem
  padding: 0.2rem
.is-rounded
 background-color:#F7931E
 border-radius:50%
 color:#fff
 padding:0.5rem

.header
  color:#F7931E

.project-img
 height: 100px
 width: 150px
 object-fit: cover
.header-service
  margin-top: 1rem
  margin-bottom: 1rem
  color:#F7931E

section
  height: 100vh

.content
  width: 30rem
.dark
  background-color:#F7931E

.contact-card
  display: inline-block
  width: 10rem
.rec-logo
  width: 20em

.page1,page2
 width: 100%
 height: 650px
.page-content
  width: 50%
  height: 550px
  float: left

.proj-content
  height: 7.5rem
  overflow: hidden

.page1
  page-break-after: always
  padding: 2rem
  height:100vh
.page2
  padding: 1rem

.right
  height:100%
  padding:0 1rem 2rem 2rem
.left
  height:100%
  padding:0 1.5rem 1.5rem 1.5rem
.other-proj
  margin-top: -2.5rem


@media print
  .no-print, .no-print *
    display: none !important

  .core-values
   width:15rem
  .service
   width: 35rem
   margin-top: -1.4rem

  .cert
   font-size: 16px
  .container
   margin:0
</style>
