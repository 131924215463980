<template>
  <td>
    <div class="level">
      <router-link
        class="level-item material-icons mx-2 table-options__preview-btn"
        data-test-id="`preview-btn`"
        title="`Preview"
        :to="`/enterprise/${slug}/${liableDoc.id}`"
      >
        preview
      </router-link>
      <router-link
        class="level-item material-icons mx-2"
        data-test-id="`edit-btn`"
        title="`Edit"
        :to="`/enterprise/${slug}/${liableDoc.id}/edit`"
      >
        edit
      </router-link>
      <button
        class="
          level-item
          button
          is-warning is-light
          material-icons
          is-small
          mx-1
        "
        data-test-id="fast-forward"
        @click="fastForward()"
      >
        fast_forward
      </button>
    </div>
  </td>
  <td>
    <div class="level">
      <p v-if="status == `Requested`">
        <BaseSelect
          style="width: 100%"
          title="Decision"
          :choices="decisionTypes"
          v-model="fields.decision"
        />
      </p>
      <p v-if="status == `Assessment Passed`">
        <BaseSelect
          style="width: 100%"
          title="Decision"
          :choices="decisionTypes"
          v-model="fields.decision"
        />
      </p>
      <div v-if="status == 'Released' && canAssess()">
        <p>Acceptance Proof:</p>
        <input type="file" ref="acceptanceProof" class="level-item is-size-7" />
        <br />
        <p>Accepted By:</p>
        <BaseDocument
          class="level-item is-size-7"
          title="Employee"
          v-model="fields.acceptedBy"
          @click="acceptedByType = 'Employee'"
          :isAddable="false"
        />
        <div>
          <BaseDocument
            class="level-item is-size-7"
            title="Client Representatives"
            v-model="fields.acceptedBy"
            @click="acceptedByType = 'ClientRepresentative'"
            :isAddable="false"
          />
        </div>
      </div>
      <div v-if="status == `Authorized`">
        <button
          style="width: 100%"
          class="level-item button is-warning is-light :hover"
          @click="fields.decision = true"
        >
          Accept
        </button>
      </div>
    </div>
  </td>
  <td>
    <p class="control control-button" v-if="status != `Requested`">
      <button class="button is-danger material-icons" @click="revertStatus()">
        restart_alt
      </button>
    </p>
  </td>
</template>

<script>
import BaseSelect from "@/components/base_fields/BaseSelect";
import BaseDocument from "@/components/base_fields/BaseDocument";

import { computed, ref, watch } from "vue";
import ResourceService from "@/services/resource_service.js";
import { snakeCase } from "change-case";
import plr from "pluralize";
export default {
  components: { BaseSelect, BaseDocument },
  emits: ["refresh"],
  props: { liableDoc: Object, index: Number, user: Object },
  setup(props, { emit }) {
    const decisionTypes = [
      { label: "Accept", value: true },
      { label: "Deny", value: false },
    ];
    const fields = ref({
      decision: null,
      acceptedBy: null,
      acceptedByType: null,
    });
    const acceptedByType = ref(null);
    const acceptanceProof = ref(null);
    const liableType = computed(() => props.liableDoc.type);
    const status = computed(() => props.liableDoc.status);

    watch(fields, updateStatus, { deep: true });
    const slug = ref(plr(snakeCase(liableType.value)));

    async function fastForward() {
      const { type, id } = props.liableDoc;
      const slug = plr(snakeCase(type));
      const resourceService = new ResourceService(slug);
      const data = {
        resourceService,
        id,
        decision: true,
        acceptedBy: props.user.id,
        acceptedByType: "Employee",
      };
      try {
        updateRequested(data);
        updateAssessment(data);
        updateAuthorized(data);
      } catch (error) {
        console.log(error);
      }
    }

    async function revertStatus() {
      const { id, status } = props.liableDoc;
      const resourceService = new ResourceService(slug.value);
      const data = {
        resourceService,
        id,
      };
      try {
        switch (status) {
          case "Assessment Passed":
          case "Assessment Failed":
            revertRequested(data);
            break;
          case "Authorized":
          case "Unauthorized":
            revertAssessment(data);
            break;
          case "Released":
            revertAuthorized(data);
            break;
          case "Accepted":
            revertReleased(data);
            break;
        }
      } catch (err) {
        console.err(err);
      }
    }

    async function updateStatus({ decision, acceptedBy }) {
      const { id, status } = props.liableDoc;
      const resourceService = new ResourceService(slug.value);
      const data = {
        resourceService,
        id,
        decision,
        acceptedBy,
        acceptedByType: acceptedByType.value,
        acceptanceProof: acceptanceProof.value?.files[0],
      };
      try {
        switch (status) {
          case "Requested":
            updateRequested(data);
            break;

          case "Assessment Passed":
            updateAssessment(data);
            break;

          case "Authorized":
            updateAuthorized(data);
            break;

          case "Released":
            updateReleased(data);
            break;
        }
      } catch (err) {
        console.err(err);
      }
    }
    async function updateRequested(data) {
      const { resourceService, id, decision } = data;
      await resourceService.update(id, {
        assessedBy: props.user.id,
        assessedOn: Date.now(),
        assessmentStatus: decision,
      });
      emit("refresh");
    }
    async function updateAssessment(data) {
      const { resourceService, id, decision } = data;
      await resourceService.update(id, {
        authorizedBy: props.user.id,
        authorizedOn: Date.now(),
        authorizationStatus: decision,
      });
      emit("refresh");
    }

    async function updateAuthorized(data) {
      const { resourceService, id } = data;
      await resourceService.update(id, {
        releasedBy: props.user.id,
        releasedOn: Date.now(),
      });
      emit("refresh");
    }
    async function updateReleased(data) {
      const {
        resourceService,
        id,
        acceptedBy,
        acceptedByType,
        acceptanceProof,
      } = data;
      await resourceService.update(id, {
        acceptedBy: acceptedBy,
        acceptedOn: Date.now(),
        acceptedByType: acceptedByType,
        acceptanceProof: acceptanceProof,
      });
      emit("refresh");
    }

    async function revertRequested(data) {
      const { resourceService, id } = data;
      await resourceService.update(id, {
        assessedBy: null,
        assessedOn: null,
        assessmentStatus: null,
      });
      emit("refresh");
    }

    async function revertAssessment(data) {
      const { resourceService, id } = data;
      await resourceService.update(id, {
        authorizedBy: null,
        authorizedOn: null,
        authorizationStatus: null,
      });
      emit("refresh");
    }

    async function revertAuthorized(data) {
      const { resourceService, id } = data;
      await resourceService.update(id, {
        releasedBy: null,
        releasedOn: null,
      });
      emit("refresh");
    }

    async function revertReleased(data) {
      const { resourceService, id } = data;
      await resourceService.update(id, {
        acceptedBy: null,
        acceptedOn: null,
        acceptedByType: null,
      });
      emit("refresh");
    }

    function canAssess() {
      return props.user.position != "Quantity Surveyor";
    }

    return {
      BaseDocument,
      liableType,
      status,
      decisionTypes,
      fields,
      canAssess,
      revertStatus,
      fastForward,
      slug,
      acceptanceProof,
    };
  },
};
</script>

<style lang="sass" scoped>
.control
  width: 5rem

.control-button
  width: 2rem

.button.is-warning.is-light
  background-color: #ffe8cc

.button.is-warning.is-light:hover
  background-color: #121212
  color: #FFFFFF
</style>
