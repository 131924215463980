import { generateID, isDisabled } from "./concerns.js";
import { ref, watch, toRefs, onMounted } from "vue";
import UniqService from "@/services/uniq_service.js";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { snakeCase } from "lodash";
const { DateTime } = require("luxon");

class InputHandler {
  constructor(props, emitFxn, defaultValue) {
    this.emitFxn = emitFxn;
    this.props = props;
    this.fieldValue = ref(defaultValue);
  }

  exec() {
    const uniqueID = generateID(this.props);
    this.uniqueID = uniqueID;
    this.handleIncomingField();
    if (this.props.unique) this.validateUnique();
    this.emitOutgoingField();
    return {
      uniqueID,
      isDisabled,
      fieldValue: this.fieldValue,
    };
  }

  validateUnique() {
    onMounted(async () => {
      const store = useStore();
      const { meta } = useRoute();
      const { resourceID } = store.getters;
      const propSlug = snakeCase(this.props.title);
      const uniqService = new UniqService(meta.slug, propSlug);
      const input = document.querySelector(`input#${this.uniqueID}`);
      input.addEventListener("blur", async (event) => {
        const val = event.target.value;
        const isUniq = await uniqService.index(val, resourceID);
        if (!isUniq) {
          const errMsg = `${val} already exists; unique value required.`;
          input.setCustomValidity(errMsg);
          input.checkValidity();
          input.reportValidity();
        }
      });
      input.addEventListener("change", () => {
        input.setCustomValidity("");
      });
    });
  }

  handleIncomingField() {
    const { modelValue } = toRefs(this.props);
    onMounted(() => {
      const mv = modelValue.value;
      this.fieldValue.value = mv;
      if (this.props.type == "date" && mv) {
        const htmlFormat = "yyyy-MM-dd";
        const dateInput = DateTime.fromISO(mv).toFormat(htmlFormat);
        this.fieldValue.value = dateInput;
      } else if (this.props.type == "datetime-local" && mv) {
        const htmlFormat = "yyyy-MM-dd'T'HH:ss";
        const dateInput = DateTime.fromISO(mv).toFormat(htmlFormat);
        this.fieldValue.value = dateInput;
      }
    });
  }

  emitOutgoingField() {
    watch(this.fieldValue, (newFieldValue) => {
      this.emitFxn("update:modelValue", newFieldValue);
    });
  }
}

export default InputHandler;
