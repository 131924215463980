import { pascalCase } from "change-case";

function generateGetters(store) {
  if (!store.getters) store["getters"] = {};
  Object.keys(store.state).forEach((stateVariable) => {
    if (!store.getters[stateVariable]) {
      store.getters[stateVariable] = (state) => {
        return state[stateVariable];
      };
    }
  });
  return store;
}

function generateMutations(store) {
  if (!store.mutations) store["mutations"] = {};
  Object.keys(store.state).forEach((stateVariable) => {
    if (!store.mutations[stateVariable]) {
      store.mutations[stateVariable] = (state, payload) => {
        state[stateVariable] = payload;
      };
    }
  });
  return store;
}

function generateActions(store) {
  if (!store.actions) store["actions"] = {};
  Object.keys(store.state).forEach((stateVariable) => {
    const actionKey = `set${pascalCase(stateVariable)}`;
    if (!store.actions[actionKey]) {
      store.actions[actionKey] = ({ commit }, payload) => {
        commit(stateVariable, payload);
      };
    }
  });
  return store;
}

function generateDefaults(store) {
  let tempStore = store;
  tempStore = generateGetters(store);
  tempStore = generateMutations(store);
  tempStore = generateActions(store);
  return tempStore;
}

export default generateDefaults;
