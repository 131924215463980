<template>
  <div>
    <NavigationBar />
    <NotificationBar />
    <router-view />
  </div>
</template>

<script>
import NotificationBar from "@/components/NotificationBar.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import AuthService from "@/services/auth_service.js";
import store from "@/store/index.js";
import { useRouter, useRoute } from "vue-router";
import { useHead } from "@vueuse/head";

export default {
  components: { NavigationBar, NotificationBar },
  setup() {
    const router = useRouter();
    const route = useRoute();
    autoLogin();
    developmentLogin();

    if (process.env.VUE_APP_ENVIRONMENT == "PRODUCTION") {
      useHead({
        meta: [
          {
            "http-equiv": "Content-Security-Policy",
            content: "upgrade-insecure-requests",
          },
        ],
      });
    }

    function autoLogin() {
      const userID = localStorage.getItem("userId");
      const userToken = localStorage.getItem("userToken");
      if (userID != "null" && userToken !== null) {
        store.dispatch("setUserToken", userToken);
        store.dispatch("setUserId", userID);
      } else {
        if (route.path != "/") {
          router.push({ name: "Account Login" });
        }
      }
    }

    function developmentLogin() {
      if (process.env.VUE_APP_ENVIRONMENT == "DEVELOPMENT") {
        const authService = new AuthService();
        authService
          .read("jamespatrickpe@gmail.com", "p3GeaTt5SX")
          .then(() => {
            store.commit("noteSuccess", "DEVELOPER MODE LOGIN");
          })
          .catch((err) => {
            store.commit("noteError", err);
          });
      }
    }
  },
};
</script>
<style lang="sass">
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

#nav
  padding: 30px
  a
    font-weight: bold
    color: #2c3e50
    &.router-link-exact-active
      color: #42b983
</style>
