<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div class="columns is-centered">
        <AuthTitle />
        <div class="column is-narrow pl-4" align="left">
          <form @submit.prevent="reclaimPassword()">
            <input
              class="input mb-2"
              v-model="email"
              placeholder="Email"
              type="email"
              :size="8"
              maxlength="32"
            />
            <div class="level">
              <AuthFooter />
              <button
                class="level-item button is-success"
                @click.prevent="reclaimPassword"
              >
                Reclaim Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from "@/store/index.js";
import AuthFooter from "@/views/auth/AuthFooter.vue";
import AuthTitle from "@/views/auth/AuthTitle.vue";
import PasswordService from "@/services/password_service.js";
import { ref } from "vue";

export default {
  components: { AuthTitle, AuthFooter },
  setup() {
    const email = ref(null);
    const passwordService = new PasswordService();

    function reclaimPassword() {
      passwordService
        .create(email.value)
        .then(() => {
          store.commit("noteInfo", `Sent new password to ${email.value}`);
        })
        .catch((err) => {
          store.commit("noteError", err);
        });
    }

    return { email, reclaimPassword };
  },
};
</script>
