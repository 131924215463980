<template>
  <section class="section print-container">
    <div class="columns">
      <div class="column is-half">
        <h1 class="has-text-left content is-size-1 header">Core Values</h1>
        <p class="has-text-left content">
          As collective group of passionate individuals of trade; and an
          enterprise which embraces the principles of capitalism and fair trade
          we value :
        </p>
        <ul class="has-text-left sub-content">
          <li>* Integrity</li>
          <li>* Honesty</li>
          <li>* Dedication</li>
          <li>* Personal Excellence</li>
          <li>* Continuous Self-Improvement</li>
          <li>* Mutual Respect</li>
        </ul>
        <br />
        <p class="has-text-left content">
          We hold accountability for our customers, partners, and employees by
          honoring our commitments, providing results, and striving for the
          highest quality of service.
        </p>
      </div>
      <div class="column is-half no-print img">
        <img src="@/assets/plumber-values.svg" class="team" />
      </div>
    </div>
  </section>
</template>

<style lang="sass" scoped>
.header
  color:#F7931E

.plumber
  height:100vh
  margin-top: -1rem

.content
  width: 30rem

.team
 margin-top: -5rem
 height: 35rem

section
  height: 100vh

.sub-content
  margin-left:2rem
  width: 30rem

@media print
  .no-print, .no-print *
    display: none !important
  .print-container
   width: 50vh
   height: 50vh


@media (max-width: 1023px)

@media (max-width: 768px)
  section
    margin-top: -33rem
  .img
    margin-top: 5rem
  .content
   text-align: center
   width: 100%
</style>
