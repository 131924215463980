<template>
  <router-link
    v-if="title != 'Registration'"
    to="/registration"
    class="button mr-2 level-item"
  >
    Registration
  </router-link>
  <router-link
    v-if="title != 'Password Reclamation'"
    to="/password_reclamation"
    class="button mr-2 level-item"
  >
    Password Reclamation
  </router-link>
  <router-link
    v-if="title != 'Account Login'"
    to="/account_login"
    class="button mr-2 level-item"
  >
    Account Login
  </router-link>
  <router-link
    v-if="title != 'Password Management'"
    to="/password_management"
    class="button mr-2 level-item"
  >
    Password Management
  </router-link>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  setup() {
    const { meta } = useRoute();
    return { ...meta };
  },
};
</script>
