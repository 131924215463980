<template>
  <div class="container pb-6">
    <div class="mt-4">
      <ModuleTitle />
    </div>
    <hr />
    <div class="level">
      <div class="level-left">
        <BaseInput
          class="level-item"
          type="date"
          v-model="fields.startDate"
          :size="20"
          title="Start Date"
        />
        <BaseInput
          class="level-item"
          type="date"
          v-model="fields.endDate"
          title="End Date"
        />
      </div>
      <div class="level-right">
        <input
          type="text"
          v-model="fields.referenceNumber"
          class="input is-normal level-item"
          style="width: 40%"
          data-test-id="reference"
        />
        <select
          v-model="fields.type"
          class="select is-normal level-item"
          style="width: 40%"
          data-test-id="typeSort"
        >
          <option v-for="(choice, index) in typeChoices" :key="index">
            {{ choice }}
          </option>
        </select>

        <span v-if="zipLink">
          <a
            :href="zipLink.link"
            @click="clearLink()"
            class="level-item button is-success mx-1"
            data-test-id="export"
          >
            <div class="column is-one-fifth">
              <span class="material-icons">file_download</span>
            </div>
            <div class="column">Dowload Audit Report</div>
          </a>
        </span>
        <span v-else>
          <button
            @click="
              dowloadAuditReport('Audit Report', exportAudit(auditReport))
            "
            class="level-item button is-success mx-1"
            data-test-id="export"
          >
            <div class="column">Generate Audit Report</div>
          </button>
        </span>
      </div>
    </div>

    <table
      class="table mt-4 is-fullwidth is-hoverable is-striped"
      v-if="auditReport"
      :key="refreshFlag"
    >
      <thead>
        <tr align="left">
          <th>Date Requested</th>
          <th>Type</th>
          <th>Reference Number</th>
          <th>Amount</th>
          <th>Proof Image</th>
          <th colspan="2" style="text-align: center">Preview</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(liableDoc, index) in auditReport" :key="index" align="left">
          <td>{{ formatDate(liableDoc.requestedOn) }}</td>
          <td>{{ capitalCase(liableDoc.type) }}</td>
          <td>{{ liableDoc.referenceNumber }}</td>
          <td>
            <p class="block cost">
              {{ formatMoney(liableDoc.amount || 0) }}
            </p>
          </td>
          <td>{{ liableDoc.image.storageDirectory ?? "" }}</td>
          <td colspan="2">
            <router-link
              class="level-item material-icons mx-2 table-options__preview-btn"
              data-test-id="preview-btn-0"
              title="`Preview"
              :to="`/enterprise/${plr(snakeCase(liableDoc.type))}/${
                liableDoc.id
              }`"
            >
              preview
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { auditReportService } from "@/services/audit_service";
import { snakeCase, capitalCase } from "change-case";
import plr from "pluralize";
import ModuleTitle from "@/components/ModuleTitle.vue";
import { onMounted, ref, watch } from "vue";
import BaseInput from "../../components/base_fields/BaseInput.vue";
import ResourceService from "@/services/resource_service";
import store from "@/store/index.js";
import { DateTime } from "luxon";
import { accounting } from "@/global.js";
const { formatMoney } = accounting;

export default {
  components: {
    ModuleTitle,
    BaseInput,
  },
  setup() {
    const user = ref(null);
    const typeChoices = [
      "All",
      "Purchase Order",
      "Subcon Payment Slip",
      "Indirect Cost",
      "Project Voucher",
    ];
    const fields = ref({
      startDate: getMonth("before"),
      endDate: getMonth("after"),
      referenceNumber: null,
      type: "All",
    });
    const auditReport = ref(null);
    const refreshFlag = ref(Date.now());

    async function refreshTable() {
      const { startDate, endDate, referenceNumber, type } = fields.value;
      const { data } = await auditReportService.index(
        startDate,
        endDate,
        referenceNumber,
        type
      );
      auditReport.value = data;
      refreshFlag.value = Date.now();
    }

    function formatDate(timestamp) {
      const date = DateTime.fromISO(timestamp);
      return date.toLocaleString(DateTime.DATETIME);
    }

    function getMonth(status) {
      if (status == "before") {
        const date = DateTime.fromISO(DateTime.local().plus({ months: -1 }));
        return date.toString().split("T")[0];
      } else if (status == "after") {
        const date = DateTime.fromISO(DateTime.local());
        return date.toString().split("T")[0];
      }
    }

    async function getUser() {
      await new ResourceService("employees")
        .read(store.getters.userId)
        .then((el) => (user.value = { position: el.position, id: el.id }));
    }
    const zipLink = ref(null);
    async function dowloadAuditReport(filename, auditData) {
      const formData = {
        filename: filename.concat(Date.now()),
        auditData,
      };
      const link = await auditReportService.create(formData);
      zipLink.value = link;
    }
    const exportAudit = (data) => {
      return data.map((x) => {
        return {
          date: formatDate(x.requestedOn),
          type: x.type,
          referenceNumber: x.referenceNumber,
          image: x.image.storageDirectory,
        };
      });
    };
    async function clearLink() {
      await auditReportService.delete({ filename: zipLink.value["filename"] });
      zipLink.value = null;
    }
    onMounted(() => {
      refreshTable();
      getUser();
    });

    watch(
      fields,
      () => {
        refreshTable();
      },
      { deep: true }
    );

    return {
      refreshFlag,
      capitalCase,
      refreshTable,
      formatMoney,
      user,
      getUser,
      formatDate,
      auditReport,
      snakeCase,
      typeChoices,
      fields,
      plr,
      zipLink,
      clearLink,
      dowloadAuditReport,
      exportAudit,
    };
  },
};
</script>
<style scoped lang="sass"></style>
