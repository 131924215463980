<template>
  <section class="section no-print">
    <h1 class="has-text-left is-size-1 header-service">
      ADDRESS AND CONTACT INFORMATION
    </h1>
    <div class="info">
      <p class="has-text-left">
        Official DTI Registered Name : <b>Ronkowski Engineers Co.</b>
      </p>
      <p class="has-text-left">Brand Name and Trademark : <b>REC</b></p>
      <p class="has-text-left">
        Address:
        <b
          >Great Eastern Resins, L3 & L4 B22, Purok Maligaya 2, Barrio Mambugan,
          Antipolo City</b
        >
      </p>
      <p class="has-text-left">
        Contact Numbers :
        <b>09156304970</b>,
        <b>0289427048</b>
      </p>
      <p class="has-text-left">
        Email:
        <b>projects@rec.engineering</b>,
      </p>
    </div>
    <div class="level">
      <iframe
        class="level-item"
        width="700"
        height="600"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Ronkowski%20Engineers%20Co&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      />
    </div>
  </section>
</template>
<style lang="sass" scoped>
.footer-logo
  width: 15rem
  margin-right:40rem
.footer-subtitle
  width:25rem

.social-media
 margin-top: 1rem
 font-size:2.5rem
 color:#4267B2


.level-item
 margin-top: -2rem

.is-rounded
 background-color:#F7931E
 border-radius:50%
 color:#fff
 padding:0.5rem

.header
  color:#F7931E


.header-service
  margin-bottom: 3rem
  color:#F7931E

section
  height: 100vh
  margin-top:10rem

.content
  width: 30rem
.dark
  background-color:#F7931E

.contact-card
  display: inline-block
  width: 10rem
.rec-logo
  width: 20em


.card-content
  .proj-content
    height: 7.5rem
    overflow: hidden

.gmap_canvas
  overflow: hidden
  background: none !important
  height:500px
  width:800px

.mapouter
  text-align:right
  height:500px
  width:600px
.info
 margin-top:-2rem
 margin-bottom:3rem

@media (max-width: 1023px)

@media (max-width: 768px)
  section
    margin-top: 210rem
    margin-bottom: 10rem
  iframe
   width:100%
   height:20rem
  .is-size-1
    font-size: 1.8rem !important
    margin-bottom:3rem
    width:100%
</style>
