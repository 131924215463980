import { singular } from "pluralize";
import { capitalCase } from "change-case";

class ResourceRouter {
  static routes = [];

  constructor(title) {
    this.title = title;
    this.subtitle = null;
    this.slug = null;
    this.logo = null;
    this.resourceName = capitalCase(singular(title));
  }

  setSubtitle(subtitle) {
    this.subtitle = subtitle;
    return this;
  }

  setSlug(slug) {
    this.slug = slug;
    return this;
  }

  setLogo(logo) {
    this.logo = logo;
    return this;
  }
}

export default ResourceRouter;
