const axios = require("axios");
import { DEF_REQ_TIMEOUT } from "@/global.js";

class FrontPageService {
  headerOptions = { "Content-Type": "multipart/form-data" };
  constructor() {
    this.axios = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_HOST}/frontpage`,
      timeout: parseInt(DEF_REQ_TIMEOUT),
    });
    this.parseError = parseError;
    return this;
  }

  index() {
    return this.axios
      .get("/")
      .then((res) => {
        return res.data;
      })
      .catch(this.parseError);
  }
}

function parseError(errObj) {
  console.log(errObj);
  console.error(errObj);
  const status = errObj?.response?.status;
  if (status == 500) {
    throw "Internal Server Error";
  } else if (status == 404) {
    throw "Resource Not Found";
  } else if (status == 403) {
    throw "Unauthorized Access";
  } else if (status == 400) {
    throw "Invalid Request / Validation Failed";
  } else {
    console.log("frontpage_service.js");
    throw `General Error Status Code : ${
      !status ? "Backend Server is Down!" : status
    }`;
  }
}

export default FrontPageService;
