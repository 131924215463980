require("@/assets/main.sass");
require("@vueform/multiselect/themes/default.css");

import VueGoogleMaps from "@fawmi/vue-google-maps";
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/index.js";
import { router } from "./router";
import VueGapi from "vue-gapi";
import { createHead } from "@vueuse/head";

const head = createHead();

createApp(App)
  .use(head)
  .use(store)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_API_KEY,
      libraries: "places",
    },
  })
  .use(VueGapi, {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
      "https://docs.googleapis.com/$discovery/rest?version=v1",
      "https://script.googleapis.com/$discovery/rest?version=v1",
    ],
    scope:
      "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/script.projects",
  })

  .mount("#app");
