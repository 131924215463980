import { singular } from "pluralize";
import ResourceRouter from "./resource_router";
import MapService from "@/services/map_service";
import store from "@/store/index.js";
import projectStore from "@/store/project.js";
import projectPanels from "./projects_panels.js";
import resourceStore from "@/store/resource.js";

class ProjectsRouter extends ResourceRouter {
  static idForms = [
    "edit",
    "display",
    "history",
    "bin",
    "contract",
    "init",
    "bill",
  ];

  constructor() {
    super("Projects");
    this.controlPanel = null;
    this.routes = [];
  }

  setControlPanel(controlPanel) {
    this.controlPanel = controlPanel;
    return this;
  }

  buildRoute() {
    const { title, subtitle, slug, logo, resourceName, controlPanel } = this;
    this.routes.push({
      name: title,
      path: `/enterprise/${slug}`,
      component: this.componentFxn,
      meta: {
        isOverlayOpen: false,
        formMode: null,
        layout: "map",
        resourceName,
        title,
        subtitle,
        slug,
        logo,
        controlPanel,
      },
      children: [
        {
          path: "new",
          name: `New Project`,
          component: this.componentFxn,
          meta: {
            isOverlayOpen: true,
            formMode: "new",
            title: "New Project",
            overlaySlug: `resource_forms/project/Project.vue`,
          },
        },
        {
          path: "bin",
          name: `Deleted Projects`,
          component: this.componentFxn,
          meta: {
            formMode: "bin",
            title: "Deleted Projects",
            overlaySlug: null,
          },
        },
        {
          path: ":id",
          name: `Display Project`,
          component: this.componentFxn,
          meta: {
            formMode: "display",
            title: "Display Project",
            overlaySlug: null,
            documentKey: "editorDocID",
            isOverlayOpen: false,
          },
          children: projectPanels,
        },
      ],
    });

    return this;
  }

  setStates(formMode, { meta, params }) {
    const { title, slug } = meta;
    const modelName = singular(title);

    if (!store.hasModule("resource")) {
      store.registerModule("resource", resourceStore);
    }
    if (!store.hasModule("map")) {
      store.registerModule("map", projectStore);
    }

    store.dispatch("setResourceId", null);
    store.dispatch("setResourceName", modelName);
    store.dispatch("setResourceService", new MapService(slug));

    if (formMode == "new") {
      store.dispatch("setResourceId", null);
    } else if (ProjectsRouter.idForms.includes(formMode)) {
      store.dispatch("setResourceId", params.id);
    } else if (formMode?.includes("amend")) {
      store.dispatch("setResourceId", params.id);
      store.dispatch("setAmendmentId", params.amendment_id);
    } else if (formMode?.includes("progress")) {
      store.dispatch("setResourceId", params.id);
      store.dispatch("setProgressId", params.progress_id);
    } else {
      store.dispatch("setResourceId", null);
      store.dispatch("setAmendmentId", null);
      store.dispatch("setProgressId", null);
    }
  }

  getRoute() {
    return this.routes;
  }

  componentFxn = () => {
    return import(
      /* webpackChunkName: "Map" */
      "@/views/map/Map.vue"
    );
  };
}

export default new ProjectsRouter();
