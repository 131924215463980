<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column trademark">
          <img src="@/assets/rec_logo_light.svg" class="footer-logo" />
          <p class="has-text-left footer-subtitle">
            Ronkowski Engineers Co. (REC) was founded in order to offer
            businesses Lean Style and Proactive Building Maintenance Design and
            Services.
          </p>
          <div>
            <div class="has-text-left mb-5">
              <a
                href="https://www.facebook.com/ronkowskiengineering"
                class="is-large material-icons social-media"
                >facebook</a
              >
            </div>
            <div class="has-text-left copyright-desktop">
              Ronkowski Engineers Co. © {{ year }}
            </div>
          </div>
        </div>
        <div class="column info">
          <h1
            class="
              is-size-3
              has-text-weight-bold
              is-uppercase
              has-text-left
              header
            "
          >
            Company Info
          </h1>
          <div>
            <p class="has-text-left">
              Official DTI Registered Name : <b>Ronkowski Engineers Co.</b>
            </p>
            <p class="has-text-left">Brand Name and Trademark : <b>REC</b></p>
            <p class="has-text-left">
              Address:
              <b
                >Great Eastern Resins, L3 & L4 B22, Purok Maligaya 2, Barrio
                Mambugan, Antipolo City</b
              >
            </p>
            <p class="has-text-left">
              Contact Numbers :
              <b>09156304970</b>,
              <b>0289427048</b>
            </p>
            <p class="has-text-left">
              Email:
              <b>projects@rec.engineering</b>,
            </p>
            <div
              class="
                columns
                is-variable
                is-1-mobile
                is-0-tablet
                is-3-desktop
                is-8-widescreen
                is-2-fullhd
              "
            >
              <div class="column brochure">
                <router-link class="button is-link" :to="{ name: 'Brochure' }">
                  Get Brochure
                </router-link>
              </div>
              <div class="column">
                <a class="button is-link" href="company_profile.pdf" download>
                  Get Company Profile
                </a>
              </div>
              <div class="column"></div>
              <div class="column">
                <div class="has-text-center copyright-mobile">
                  Ronkowski Engineers Co. © {{ year }}
                </div>
              </div>
              <div class="column"></div>
              <div class="column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: ["year"],
};
</script>
<style lang="sass" scoped>
.footer-logo
  width: 15rem
  margin-right:40rem
.footer-subtitle
  width:25rem

.social-media
 margin-top: 1rem
 font-size:2.5rem
 color:#4267B2

.copyright-mobile
 display: none
.is-rounded
 background-color:#F7931E
 border-radius:50%
 color:#fff
 padding:0.5rem

.header
  color:#F7931E


.header-service
  margin-bottom: 3rem
  color:#F7931E

section
  height: 100%

.content
  width: 30rem
.dark
  background-color:#F7931E

.rec-logo
  width: 20em



.info
 margin-top:-2rem
 margin-bottom:3rem

@media (max-width: 1023px)

@media (max-width: 768px)
 section
  margin: 0

 .footer-subtitle
   text-align: center
   width: 100%
 .is-link
   margin:0
   width: 100%
 .brochure
   margin-top:1rem
 .copyright-desktop
  display: none
 .copyright-mobile
  display: block
</style>
