<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div class="columns is-centered">
        <AuthTitle icon="how_to_reg" msg="Sign Up" />
        <div class="column is-narrow pl-4" align="left">
          <form @submit.prevent="assessRegForm">
            <input
              data-test-id="email"
              class="input mb-2"
              v-model="email"
              placeholder="Email"
              type="email"
              :size="8"
              maxlength="32"
            />
            <input
              data-test-id="password"
              class="input mb-2"
              v-model="password"
              type="password"
              placeholder="Password"
              :size="8"
              maxlength="32"
            />
            <input
              data-test-id="password-confirmation"
              class="input mb-2"
              v-model="passwordConfirmation"
              type="password"
              placeholder="Password Confirmation"
              :size="8"
              maxlength="32"
            />
            <div class="level">
              <AuthFooter />
              <button
                class="level-item button is-success"
                @click.prevent="assessRegForm"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
import AuthFooter from "@/views/auth/AuthFooter.vue";
import AuthTitle from "@/views/auth/AuthTitle.vue";
import AuthService from "@/services/auth_service.js";
import { useStore } from "vuex";

export default {
  components: { AuthTitle, AuthFooter },
  setup() {
    const email = ref(null);
    const password = ref(null);
    const passwordConfirmation = ref(null);
    const store = useStore();

    function assessRegForm() {
      const authService = new AuthService();
      if (password.value == passwordConfirmation.value) {
        authService
          .create({ email: email.value, password: password.value })
          .then((msg) => {
            store.commit("noteSuccess", msg);
            email.value = "";
            password.value = "";
            passwordConfirmation.value = "";
          })
          .catch((err) => {
            store.commit("noteError", err);
          });
      } else {
        store.commit("noteWarning", "Password Confirmation is Invalid !");
      }
    }

    return { email, password, passwordConfirmation, assessRegForm };
  },
};
</script>

<style lang="sass" scoped>
.registration-banner
  border-right: solid thin black
</style>
