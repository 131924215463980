<template>
  <section class="is-large is-info is-vcentered">
    <div class="hero-body is-vcentered is-height-80">
      <div class="is-vertically-centered">
        <span class="material-icons md-72">dangerous</span>
        <p class="title">Module or URL not Found</p>
        <p class="subtitle">Invalid URL</p>
      </div>
    </div>
  </section>
</template>
