import MainService from "./main_service";

export default class SelectableService extends MainService {
  constructor(slug, fieldName = "_REP") {
    super(slug, "selectable");
    this.fieldName = fieldName;
  }

  index(query, limit = 10) {
    return this.axios
      .get(`${this.slug}`, {
        params: {
          q: query,
          f: this.fieldName,
          l: limit,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return err?.response?.status;
      });
  }

  read(resourceID) {
    return this.axios
      .get(`${this.slug}/${resourceID}`, {
        params: { f: this.fieldName },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        const status = err?.response?.status;
        if (status == 404) {
          throw "Invalid Model";
        } else {
          throw "Backend Server Error";
        }
      });
  }
}
