import generateDefaults from "./defaults.js";

const baseStoreData = {
  state: {
    note: "",
    noteClass: "is-primary",
    userToken: "",
    userTitle: "",
    userId: "",
  },
  getters: {},
  mutations: {
    setupNote(state, { note, css }) {
      state.note = note;
      state.noteClass = css;
    },
    noteError(state, err) {
      state.note = err;
      state.noteClass = "is-danger";
    },
    noteSuccess(state, msg) {
      state.note = msg;
      state.noteClass = "is-success";
    },
    noteWarning(state, msg) {
      state.note = msg;
      state.noteClass = "is-warning";
    },
    noteInfo(state, msg) {
      state.note = msg;
      state.noteClass = "is-info";
    },
    resetNote(state) {
      state.note = null;
      state.noteClass = null;
    },
  },
  actions: {},
};

export default generateDefaults(baseStoreData);
