import generateDefaults from "./defaults.js";

export default generateDefaults({
  state: {
    resourceName: "",
    resourceID: null,
    resourceService: "",
    refreshFlag: null,
  },
  getters: {},
  mutations: {},
  actions: {},
});
