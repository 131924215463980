<template>
  <div class="column is-narrow auth-banner pr-4" align="right">
    <div class="title is-block">
      <span class="material-icons is-size-1 pr-2">{{ logo }}</span
      ><span class="pb-2">{{ title }}</span>
    </div>
    <div class="subtitle pl-2 is-block">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
export default {
  props: { icon: String, msg: String },
  setup() {
    const { meta, path } = useRoute();
    return { ...meta, path };
  },
};
</script>

<style lang="sass" scoped>
.auth-banner
  border-right: solid 4em gainsboro
</style>
