<template>
  <section class="hero is-large">
    <div class="hero-body">
      <div class="columns is-centered">
        <AuthTitle />
        <div class="column is-narrow pl-4" align="left">
          <form @submit.prevent="reclaimPassword()">
            <input
              class="input mb-2"
              v-model="email"
              placeholder="Email"
              type="email"
              :size="8"
              maxlength="32"
            />
            <input
              class="input mb-2"
              v-model="oldPass"
              type="password"
              :size="8"
              maxlength="32"
              placeholder="Old Password"
            />
            <input
              class="input mb-2"
              v-model="newPass"
              type="password"
              :size="8"
              maxlength="32"
              placeholder="New Password"
            />
            <input
              class="input mb-2"
              v-model="newPassConf"
              type="password"
              :size="8"
              maxlength="32"
              placeholder="New Password Confirmation"
            />
            <div class="level">
              <AuthFooter />
              <button
                class="level-item button is-success"
                @click.prevent="updatePassword"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AuthTitle from "@/views/auth/AuthTitle.vue";
import AuthFooter from "@/views/auth/AuthFooter.vue";
import PasswordService from "@/services/password_service.js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { AuthTitle, AuthFooter },
  setup() {
    const email = ref(null);
    const oldPass = ref(null);
    const newPass = ref(null);
    const newPassConf = ref(null);
    const passwordService = new PasswordService();
    const router = useRouter();
    const store = useStore();

    function updatePassword() {
      if ((newPass.value = newPassConf.value)) {
        passwordService
          .update(email.value, oldPass.value, newPass.value)
          .then(() => {
            store.commit("noteSuccess", "Login with your New Password");
            email.value = "";
            oldPass.value = "";
            newPass.value = "";
            newPassConf.value = "";
            router.push({ name: "Account Login" });
          })
          .catch((err) => console.error(err));
      } else {
        store.commit("noteWarning", "New Password must match Confirmation");
      }
    }
    return { email, oldPass, newPass, newPassConf, updatePassword };
  },
};
</script>
