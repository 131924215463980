import component from "./map_component.js";
import financePanel from "./projects_finance_panel.js";

const projectPanelRoutes = [
  {
    path: "edit",
    name: `Edit Project`,
    component,
    meta: {
      formMode: "edit",
      title: "Edit Project",
      subtitle: "Edit Proposal",
      overlaySlug: `resource_forms/project/Project.vue`,
      isOverlayOpen: true,
    },
  },
  {
    path: "history",
    name: `History of Project`,
    component,
    meta: {
      formMode: "history",
      title: "History of Project",
      subtitle: "Edit and Creation History",
      overlaySlug: null,
      isOverlayOpen: true,
    },
  },
  {
    path: "progress",
    name: `Progress of Project`,
    component,
    meta: {
      title: "Project Progress",
      subtitle: "Accomplishment for Projects",
      accompResName: "Progressive Work",
      accompResSlug: "progressive_works",
      overlaySlug: `panels/AccompDocs.vue`,
      tableSlug: `panels/AccompDocsWorks.vue`,
      documentKey: "editorDocID",
      formMode: "progress",
      isOverlayOpen: true,
      accoID: "progress_id",
    },
    children: [
      {
        path: ":progress_id",
        name: `Display Work Progress`,
        component,
        meta: {
          title: "Progress Report",
          accoMode: "display",
          accoSlug: "doc_viewers/ProgressReportViewer",
        },
      },
      {
        path: ":progress_id/edit",
        name: `Edit Work Progress`,
        component,
        meta: {
          accoMode: "edit",
          accoSlug: "ProgressiveWork",
        },
      },
      {
        path: "new",
        name: `New Work Progress`,
        component,
        meta: {
          accoMode: "new",
          accoSlug: "ProgressiveWork",
        },
      },
    ],
  },
  {
    path: "amend",
    name: `Project Amendments`,
    component,
    meta: {
      title: "Project Amendments",
      subtitle: "Management of Change Orders and Amendments",
      accompResName: "Project Amendment",
      accompResSlug: "project_amendments",
      overlaySlug: `panels/AccompDocs.vue`,
      tableSlug: `panels/AccompDocsAmends.vue`,
      documentKey: "editorDocID",
      formMode: "amend",
      isOverlayOpen: true,
      accoID: "amendment_id",
    },
    children: [
      {
        path: ":amendment_id",
        name: `Display Amendment Contract`,
        component,
        meta: {
          title: "Amendment Contract",
          accoMode: "display",
          accoSlug: "doc_viewers/ProjectAmendmentViewer",
        },
      },
      {
        path: ":amendment_id/edit",
        name: `Edit Project Amendment`,
        component,
        meta: {
          accoMode: "edit",
          accoSlug: "ProjectAmendment",
        },
      },
      {
        path: "new",
        name: `New Project Amendment`,
        component,
        meta: {
          accoMode: "new",
          accoSlug: "ProjectAmendment",
        },
      },
    ],
  },
  {
    path: "bill",
    name: `Project Bill`,
    component,
    meta: {
      title: "Project Bill",
      subtitle: "Charges to Client",
      accompResName: "Project Bill",
      accompResSlug: "project_bills",
      overlaySlug: `panels/AccompDocs.vue`,
      tableSlug: `panels/AccompDocsBills.vue`,
      documentKey: "editorDocID",
      formMode: "bill",
      isOverlayOpen: true,
      accoID: "billing_id",
    },
    children: [
      {
        path: ":billing_id",
        name: `Display Project Bill`,
        component,
        meta: {
          title: "Display Project Billing",
          accoMode: "display",
          accoSlug: "doc_viewers/ProjectBillViewer",
        },
      },
      {
        path: ":billing_id/edit",
        name: `Edit Project Bill`,
        component,
        meta: {
          accoMode: "edit",
          accoSlug: "ProjectBill",
        },
      },
      {
        path: "new",
        name: `New Project Bill`,
        component,
        meta: {
          accoMode: "new",
          accoSlug: "ProjectBill",
        },
      },
    ],
  },
  {
    path: "contract",
    name: `Project Contract`,
    component,
    meta: {
      formMode: "contract",
      title: "Project Contract",
      subtitle: "Google Docs - based Contract Generation",
      overlaySlug: "resource_forms/project/ProjectContract.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "init",
    name: `Project Initialization`,
    component,
    meta: {
      formMode: "init",
      title: "Project Initialization",
      subtitle: "Kick-off Project Details",
      overlaySlug: "resource_forms/project/Init.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "finance",
    name: `Project Finance`,
    component,
    meta: {
      formMode: "finance",
      title: "Project Finance",
      subtitle: "Accounting for Project Expenses and Payments",
      overlaySlug: "panels/Finance.vue",
      isOverlayOpen: true,
    },
    children: financePanel,
  },
  {
    path: "evaluation",
    name: `Project Evaluation`,
    component,
    meta: {
      formMode: "evaluation",
      title: "Project Evaluation",
      subtitle: "Start of Project Close-Out",
      overlaySlug: "panels/Evaluation.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "publicity",
    name: `Project Publicity`,
    component,
    meta: {
      formMode: "publicity",
      title: "Project Publicity",
      subtitle: "Expose Work-in-Progress Pics to Social Media or Website",
      overlaySlug: "panels/Publicity.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "completion_of_works",
    name: `Completion Of Works`,
    component,
    meta: {
      formMode: "completion_of_works",
      title: "Completion of Works",
      subtitle: "Preparation for Close-out and Project Evaluation",
      overlaySlug: "panels/WorkCompletion.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "coca",
    name: `Certificate of Completion and Acceptance`,
    component,
    meta: {
      formMode: "coca",
      title: "Certificate of Completion and Acceptance",
      subtitle: "Final Acceptance for Client",
      overlaySlug: "panels/COCA.vue",
      isOverlayOpen: true,
    },
  },
  {
    path: "warranties",
    name: `Warranties`,
    component,
    meta: {
      formMode: "warranty",
      title: "Warranties",
      subtitle: "Post Service Agreements",
      overlaySlug: "panels/Warranties.vue",
      isOverlayOpen: true,
    },
  },
];

export default projectPanelRoutes;
