import { createRouter, createWebHistory } from "vue-router";
import SheetRouter from "./sheet_router.js";
import projectsRouter from "./projects_router.js";
import ModuleNotFound from "../views/ModuleNotFound.vue";
import EmployeeMedia from "../views/employee_media/EmployeeMedia.vue";
import AuthorizationCenter from "../views/authorization_center/AuthorizationCenter.vue";
import DividendCenter from "../views/dividend_center/DividendCenter.vue";
import FundCenter from "../views/fund_center/FundCenter.vue";
import OperationsCenter from "../views/operations_center/OperationsCenter.vue";
import PasswordReclamation from "../views/auth/PasswordReclamation.vue";
import Registration from "../views/auth/Registration.vue";
import AccountLogin from "../views/auth/AccountLogin.vue";
import AuditReport from "../views/audit_report/AuditReport.vue";
import PasswordManagement from "../views/auth/PasswordManagement.vue";

import Home from "../views/Home.vue";
import Brochure from "../views/Brochure.vue";

let routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/brochure",
    name: "Brochure",
    component: Brochure,
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    meta: {
      title: "Registration",
      subtitle: "for Enterprise Access",
      logo: "app_registration",
    },
  },
  {
    path: "/audit_report",
    name: "Audit Report",
    component: AuditReport,
    meta: {
      title: "Audit Report",
      subtitle: "for External Reporting Purposes",
      logo: "report",
    },
  },
  {
    path: "/account_login",
    name: "Account Login",
    component: AccountLogin,
    meta: {
      title: "Account Login",
      subtitle: "for Employees and Company Affiliates",
      logo: "login",
    },
  },
  {
    path: "/password_reclamation",
    name: "Password Reclamation",
    component: PasswordReclamation,
    meta: {
      title: "Password Reclamation",
      subtitle: "Recover Password",
      logo: "password",
    },
  },
  {
    path: "/password_management",
    name: "Password Management",
    component: PasswordManagement,
    meta: {
      title: "Password Management",
      subtitle: "Update Password",
      logo: "password",
    },
  },
  {
    name: "Employee Media",
    path: "/enterprise/employee_media",
    component: EmployeeMedia,
    meta: {
      title: "Employee Media",
      subtitle: "Business and Identification Card Generator",
      logo: "badge",
      slug: "employees",
    },
    children: [
      { path: ":id", name: `Employee Media`, component: EmployeeMedia },
    ],
  },
  {
    name: "Authorization Center",
    path: "/enterprise/authorization_center",
    component: AuthorizationCenter,
    meta: {
      title: "Authorization Center",
      subtitle:
        "Validate Assessment, Authorization, Releasing and Acceptance of Liable Documents",
      logo: "verified_user",
    },
  },
  {
    name: "Fund Center",
    path: "/enterprise/fund_center",
    component: FundCenter,
    meta: {
      title: "Fund Center",
      subtitle: "Balance Fund Assets",
      logo: "price_change",
    },
    children: [{ path: ":id", name: `Fund Center`, component: FundCenter }],
  },
  {
    name: "Dividend Center",
    path: "/enterprise/dividend_center",
    component: DividendCenter,
    meta: {
      title: "Dividend Center",
      subtitle: "Distribution of Shares to Employees",
      logo: "card_membership",
    },
    children: [
      { path: ":id", name: `Dividend Center`, component: DividendCenter },
    ],
  },
  {
    name: "Operations Center",
    path: "/enterprise/operations_center",
    component: OperationsCenter,
    meta: {
      title: "Operations Center",
      subtitle: "Overview of all Projects",
      logo: "all_inclusive",
    },
    children: [
      { path: ":id", name: `Operations Center`, component: OperationsCenter },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "404", component: ModuleNotFound },
];

const companyRouter = new SheetRouter("Companies");
companyRouter
  .setSubtitle("Legal Business Entities")
  .setSlug("companies")
  .setLogo("corporate_fare")
  .setHeaders([
    "legalName",
    "TIN",
    "address",
    "email",
    "contactNumber",
    "referenceNumber",
  ])
  .buildRoute();

const serviceOfferRouter = new SheetRouter("Service Offers");
serviceOfferRouter
  .setSubtitle("Work Offers")
  .setSlug("service_offers")
  .setLogo("grade")
  .setHeaders(["label", "materialIcon"])
  .buildRoute();

const measurableUnitRouter = new SheetRouter("Measurable Units");
measurableUnitRouter
  .setSubtitle("Units of Measure")
  .setSlug("measurable_units")
  .setLogo("linear_scale")
  .setHeaders(["shorthand", "longhand"])
  .buildRoute();

const projectVoucherRouter = new SheetRouter("Project Vouchers");
projectVoucherRouter
  .setSubtitle("Miscellanoues Expenditures for Project")
  .setSlug("project_vouchers")
  .setLogo("payments")
  .setHeaders([
    "expenseCategory",
    "amount",
    "remark",
    "referenceNumber",
    "status",
    "accountableBy",
    "accountableOn",
  ])
  .setDisplaySlug("editorDocID")
  .buildRoute();

const employeeRouter = new SheetRouter("Employees");
employeeRouter
  .setSubtitle("Human Resource Asset")
  .setSlug("employees")
  .setLogo("accessibility_new")
  .setHeaders(["referenceNumber", "name", "email", "position"])
  .buildRoute();

const catalogueRouter = new SheetRouter("Catalogues");
catalogueRouter
  .setSubtitle("Supplier Materials and Equipment")
  .setSlug("catalogues")
  .setLogo("menu_book")
  .setHeaders(["company", "quotationDate", "label", "articleCount"])
  .buildRoute();

const clientRepresentativeRouter = new SheetRouter("Client Representatives");
clientRepresentativeRouter
  .setSubtitle("Appointed Associate")
  .setSlug("client_representatives")
  .setLogo("sentiment_satisfied")
  .setHeaders(["prefix", "name", "company", "position"])
  .buildRoute();

const fundRouter = new SheetRouter("Funds");
fundRouter
  .setSubtitle("Incoming Funds from Projects")
  .setSlug("funds")
  .setLogo("paid")
  .setHeaders(["name", "remark"])
  .buildRoute();

const subconAgreementRouter = new SheetRouter("Subcon Agreements");
subconAgreementRouter
  .setSubtitle("Contracts for Subcontractors in a Project")
  .setSlug("subcon_agreements")
  .setLogo("handshake")
  .setHeaders([
    "referenceNumber",
    "effectiveDate",
    "subContractor",
    "contractPrice",
    "warrantyDuration",
  ])
  .setDisplaySlug("editorDocID")
  .buildRoute();

const shareWithdrawalRouter = new SheetRouter("Share Withdrawals");
shareWithdrawalRouter
  .setSubtitle("Liquidated Shares to Project Shareholders")
  .setSlug("share_withdrawals")
  .setLogo("donut_small")
  .setHeaders([
    "amount",
    "remark",
    "recipient",
    "status",
    "accountableBy",
    "accountableOn",
    "referenceNumber",
  ])
  .buildRoute();

const expenseCategoryRouter = new SheetRouter("Expense Categories");
expenseCategoryRouter
  .setSubtitle("Category for Expenses")
  .setSlug("expense_categories")
  .setLogo("category")
  .setHeaders(["name", "remark", "parent_expense"])
  .buildRoute();

const indirectCostRouter = new SheetRouter("Indirect Costs");
indirectCostRouter
  .setSubtitle("Overhead Costs")
  .setSlug("indirect_costs")
  .setLogo("money")
  .setHeaders([
    "referenceNumber",
    "paidTo",
    "withdrawnFrom",
    "remark",
    "amount",
    "expenseCategory",
    "status",
    "accountableBy",
    "accountableOn",
  ])
  .buildRoute();

const fundAdjustmentRouter = new SheetRouter("Fund Adjustments");
fundAdjustmentRouter
  .setSubtitle("Adjustments to Funds")
  .setSlug("fund_adjustments")
  .setLogo("paid")
  .setHeaders(["amount", "remark", "fund", "adjustmentType", "adjustmentDate"])
  .buildRoute();

const dividendAdjustmentRouter = new SheetRouter("Dividend Adjustments");
dividendAdjustmentRouter
  .setSubtitle("Adjustments to Dividends")
  .setSlug("dividend_adjustments")
  .setLogo("monetization_on")
  .setHeaders([
    "adjustmentDate",
    "adjustmentType",
    "remark",
    "employee",
    "amount",
  ])
  .buildRoute();

const subconPaymentSlipRouter = new SheetRouter("Subcon Payment Slips");
subconPaymentSlipRouter
  .setSubtitle("Payments for Subcon Agremeent")
  .setSlug("subcon_payment_slips")
  .setLogo("local_atm")
  .setHeaders([
    "subconAgreement",
    "referenceNumber",
    "amount",
    "remark",
    "status",
    "accountableBy",
    "accountableOn",
  ])
  .setDisplaySlug("editorDocID")
  .buildRoute();

const purchaseOrderRouter = new SheetRouter("Purchase Orders");
purchaseOrderRouter
  .setSubtitle("Proof of Commitance to Pay")
  .setSlug("purchase_orders")
  .setLogo("price_check")
  .setHeaders([
    "company",
    "remark",
    "referenceNumber",
    "isTaxable",
    "grandTotal",
  ])
  .setDisplaySlug("editorDocID", "PurchaseOrderViewer")
  .buildRoute();

const clientPaymentRouter = new SheetRouter("Client Payments");
clientPaymentRouter
  .setSubtitle("Payments from Clients")
  .setSlug("client_payments")
  .setLogo("attach_money")
  .setHeaders([
    "applicableOn",
    "paymentType",
    "referenceNumber",
    "remark",
    "amount",
  ])
  .buildRoute();

const witholdingTaxRouter = new SheetRouter("Witholding Taxes");
witholdingTaxRouter
  .setSubtitle("BIR Form 2307")
  .setSlug("witholding_taxes")
  .setLogo("toll")
  .setHeaders(["appliedOn", "referenceNumber", "remark", "amount"])
  .buildRoute();

projectsRouter
  .setSubtitle("Proposals & Works")
  .setSlug("projects")
  .setLogo("construction")
  .setControlPanel("ProjectPanel.vue")
  .buildRoute();

routes = routes.concat(SheetRouter.routes, projectsRouter.getRoute());

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const { formMode, layout, resourceName } = to.meta;
  if (layout == "sheet") {
    SheetRouter.setStates(formMode, to);
  } else if (layout == "map" && resourceName == "Project") {
    projectsRouter.setStates(formMode, to);
  }
});

export { routes, router };
