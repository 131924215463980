<template>
  <div class="mb-2">
    <input
      class="input"
      v-bind="$attrs"
      v-model="fieldValue"
      @change="emitChange(fieldValue)"
      :type="type"
      :disabled="isDisabled()"
      :placeholder="placeholder || title"
      :data-test-ID="uniqueID"
      :id="uniqueID"
      :list="`${uniqueID}-list`"
    />
    <datalist v-if="datalist.length > 0" :id="`${uniqueID}-list`">
      <option
        v-for="suggestion in datalist"
        :key="suggestion"
        :value="suggestion"
      />
    </datalist>
  </div>
</template>

<script>
import { defaultProps } from "./concerns.js";
import InputHandler from "./input_handler.js";
import { toRefs } from "vue";
export default {
  props: {
    ...defaultProps,
    suggestions: { type: Array, default: () => [] },
    placeholder: { type: String },
    type: { type: String, default: "text" },
    unique: { type: Boolean, optional: true, default: false },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const { suggestions } = toRefs(props);
    const inputHandler = new InputHandler(props, emit);

    function emitChange(fieldValue) {
      emit("change", fieldValue);
    }

    return {
      ...inputHandler.exec(),
      datalist: suggestions,
      emitChange,
    };
  },
};
</script>
