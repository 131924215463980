import { computed, ref } from "vue";
import { isObject } from "lodash";
import ResourceService from "@/services/resource_service.js";
const { ToWords } = require("to-words");
const G_API_KEY = "AIzaSyD6wyWzpAtX9BjcgeQaWxmGj-__lYHdEHM";
const G_CLIENT_ID =
  "388039040061-h8odq9r0a58qqjj2tavsp1up3ri26n92.apps.googleusercontent.com";
const G_LAT = 14.616754799567257;
const G_LNG = 121.13857446967555;
const COMPANY_NAME = "Ronkowksi Engineers Co.";
const COMPANY_NAME_SHORT = "REC";
const COMPANY_BANK_ACCOUNT = "Ronkowski Engineers Co. BDO 002030218762";
const COMPANY_ADDRESS =
  "Great Eastern Resins, L3 & L4 B22, Purok Maligaya 2, Barrio Mambugan, Antipolo City";
const DEF_REQ_TIMEOUT = 30000;
const TAX_PERCENTAGE = 12;

const accounting = require("accounting");
accounting.settings.currency.symbol = "₱";
accounting.settings.currency.format = "%s %v";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const environment = computed(() => process.env.VUE_APP_ENVIRONMENT);

function imgSRC(imageURL) {
  return `${process.env.VUE_APP_BACKEND_HOST}/uploads/${imageURL}`;
}

function isDate(stringDate) {
  const completeTimeRegex =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
  return completeTimeRegex.test(stringDate);
}

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  var d = new Date(str);
  return d.toISOString() === str;
}

function convertDateTime(stringDate) {
  const currentDate = new Date(stringDate);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  return `${monthNames[month]} ${day}, ${year}`;
}

function convertDateForHTMLInput(stringDate) {
  const currentDate = new Date(stringDate);
  return currentDate.toLocaleDateString("en-CA");
}
const mapDefaults = {
  zoomVal: 14,
  mainMap: ref(null),
  centerVal: {
    lat: parseFloat(G_LAT),
    lng: parseFloat(G_LNG),
  },
  controlOptions: {
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    fullscreenControl: false,
    mapTypeControlOptions: {
      position: 11,
    },
  },
};

function toPesoWords(amount) {
  const toWords = new ToWords({
    localeCode: "en-US",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    },
  });

  return toWords
    .convert(amount)
    .replace("Dollars", "Pesos")
    .replace("Cents", "Centavos");
}

function docsURL(id) {
  return `https://docs.google.com/document/d/${id}/edit`;
}

function folderURL(id) {
  return `https://drive.google.com/drive/folders/${id}`;
}

function epRnd(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

async function effectiveProj(projID, isCombined = false) {
  const projectService = new ResourceService("projects");
  const amendService = new ResourceService("project_amendments");
  const project = await projectService.read(projID);
  let lastAmendID = project?.lastAmendID;
  let amend = lastAmendID ? await amendService.read(lastAmendID) : null;
  if (isCombined) {
    return Object.assign(project, amend);
  } else {
    return amend
      ? {
          ...amend,
          baseType: "ProjectAmendment",
          baseID: lastAmendID,
        }
      : {
          ...project,
          baseType: "Project",
          baseID: projID,
        };
  }
}

export {
  G_API_KEY,
  G_CLIENT_ID,
  G_LAT,
  G_LNG,
  COMPANY_NAME,
  COMPANY_NAME_SHORT,
  COMPANY_BANK_ACCOUNT,
  COMPANY_ADDRESS,
  DEF_REQ_TIMEOUT,
  TAX_PERCENTAGE,
  epRnd,
  isIsoDate,
  folderURL,
  docsURL,
  accounting,
  toPesoWords,
  mapDefaults,
  environment,
  imgSRC,
  isObject,
  isDate,
  convertDateTime,
  convertDateForHTMLInput,
  effectiveProj,
};
