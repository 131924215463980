<template>
  <section class="section print-container">
    <h1 class="has-text-left is-size-1 header-service">Featured Projects</h1>
    <div class="columns">
      <div class="column">
        <figure>
          <img src="@/assets/project/rockwell.jpg" alt="rockwell.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              Rockwell Makati
            </p>
            <p class="has-text-left is-size-7">
              Aircon Cleaning and Maintenance at Titania Wine Cellars
            </p>
          </figcaption>
        </figure>
      </div>
      <div class="column">
        <figure>
          <img src="@/assets/project/ayala.jpg" alt="ayala.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              Ayala Malls Manila Bay
            </p>
            <p class="has-text-left is-size-7">
              Laybare and Passionails Ductworks
            </p>
          </figcaption>
        </figure>
      </div>

      <div class="column">
        <figure>
          <img src="@/assets/project/electrical.jpg" alt="electrical.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              Manhattan Plaza Tower 2-Araneta Center Cubao
            </p>
            <p class="has-text-left is-size-7">Equipment Startup and Testing</p>
          </figcaption>
        </figure>
      </div>
      <div class="column">
        <figure>
          <img src="@/assets/project/bbci.jpg" alt="bbci.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              BBCI Office. 18F The Podium West Tower
            </p>
            <p class="has-text-left is-size-7">
              Supply and Installation of Chilled Water FCUs, VAVs.
            </p>
          </figcaption>
        </figure>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <figure>
          <img src="@/assets/project/ust.jpg" alt="ust.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              UST Sta.rosa
            </p>
            <p class="has-text-left is-size-7">Start-up of Fans & Blowers</p>
          </figcaption>
        </figure>
      </div>
      <div class="column">
        <figure>
          <img
            src="@/assets/project/insular_life.jpg"
            alt=" insular_life.jpg"
            class="small-pic"
          />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              Insular Life
            </p>
            <p class="has-text-left is-size-7">
              Installation of Air Conditioning and Ductworks
            </p>
          </figcaption>
        </figure>
      </div>
      <div class="column">
        <figure>
          <img src="@/assets/project/parkway.jpg" alt="parkway.jpg" />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              8F Office Space, Parkway Corporate Center
            </p>
            <p class="has-text-left is-size-7">MVAC Installation</p>
          </figcaption>
        </figure>
      </div>
      <div class="column">
        <figure>
          <img
            src="@/assets/project/fairview.jpg"
            alt="fairview.jpg"
            class="small-pic"
          />
          <figcaption>
            <p class="has-text-left is-size-6 has-text-weight-bold">
              Fairview Terraces
            </p>
            <p class="has-text-left is-size-7">
              Basement and 1st Floor Air Curtain Installation
            </p>
          </figcaption>
        </figure>
      </div>
    </div>
    <div>
      <h1 class="has-text-left is-size-4 header">Other Projects</h1>
      <div>
        <p class="has-text-left is-size-6 has-text-weight-bold">
          19th floor, NEX Tower, 6786 Ayala Ave, Legazpi Village, Makati
        </p>
        <p class="has-text-left is-size-7">Replacement of Float Valves</p>
        <p class="has-text-left is-size-6 has-text-weight-bold">
          UnionBank of the Philippines
        </p>
        <p class="has-text-left is-size-7">Equipment Start Up and Testing</p>
        <p class="has-text-left is-size-6 has-text-weight-bold">
          1603 Brixton St, Pasig, Metro Manila
        </p>
        <p class="has-text-left is-size-7">
          Change Order for Relocation of Diffuser and EAD, Additional VAV Supply
          and Installation
        </p>
      </div>
      <h1 class="has-text-left is-medium header-service link">
        For more projects visit our website https://rec.engineering or facebook
        page https://www.facebook.com/ronkowskiengineering
      </h1>
    </div>
  </section>
</template>
<style lang="sass" scoped>
.header
  color:#F7931E

section
  height: 100vh

.sub-content
  margin-left:2rem
  width: 30rem
.header-service
  margin-bottom: 1rem
  color:#F7931E

.proj-card
  width: 20rem
  display: inline-block
.small-pic
  width: 20rem
  height: 14rem
  object-fit:contain


@media print
  .no-print, .no-print *
    display: none !important
  .print-container
   width: 50vh
   height: 80vh
   float: right
   margin-top:-30rem
  .header-service
   margin-top: 2.5rem
   margin-bottom: 1rem

@media (max-width: 1023px)

@media (max-width: 768px)
  section
    margin-top: 20rem
  .is-size-1
    font-size: 2.5rem !important
    margin-bottom:1rem
</style>
