import component from "./map_component.js";
import { snakeCase } from "lodash";

const financePanel = [
  {
    name: "Profit and Loss Statement",
    path: "pl_statement",
    component,
  },
  {
    name: "Profit Sharing and Funds Allocation",
    path: "allocation",
    component,
    children: [formSH("new", "Allocation"), formSH("edit", "Allocation")],
  },
  {
    name: "Ledger",
    path: "ledger",
    component,
    children: [
      formCP("new", "Ledger"),
      formCP("edit", "Ledger"),
      formPO("new", "Ledger"),
      formPO("edit", "Ledger"),
      formPV("new", "Ledger"),
      formPV("edit", "Ledger"),
      formPS("new", "Ledger"),
      formPS("edit", "Ledger"),
    ],
  },
  {
    name: "Client Account",
    path: "client_account",
    component,
    children: [
      formWT("new", "Client Account"),
      formWT("edit", "Client Account"),
      formCP("new", "Client Account"),
      formCP("edit", "Client Account"),
      formPB("new", "Client Account"),
      formPB("edit", "Client Account"),
    ],
  },
  {
    name: "Project Taxes",
    path: "project_taxes",
    component,
    children: [formWT("new", "Project"), formWT("edit", "Project")],
  },
];

function formSH(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Share Declaration",
    "share_declarations",
    "ShareDeclaration"
  );
}

function formPO(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Purchase Order",
    "purchase_orders",
    "PurchaseOrder"
  );
}

function formPV(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Project Voucher",
    "project_vouchers",
    "ProjectVoucher"
  );
}

function formPS(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Subcon Payment Slip",
    "subcon_payment_slips",
    "SubconPaymentSlip"
  );
}

function formPB(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Project Bill",
    "project_bills",
    "ProjectBill"
  );
}

function formCP(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Client Payment",
    "client_payments",
    "ClientPayment"
  );
}

function formWT(mode, uniqName) {
  return subForm(
    mode,
    uniqName,
    "Witholding Tax",
    "witholding_taxes",
    "WitholdingTax"
  );
}

function subForm(mode, uniqName, resourceName, slug, formSlug) {
  const params = snakeCase(resourceName);
  const titleMode = mode.charAt(0).toUpperCase() + mode.slice(1);
  let path = mode == "new" ? "new" : `:${params}_id/edit`;
  path = `${slug}/${path}`;
  const name = `${titleMode} ${uniqName} ${resourceName}`;
  return {
    name,
    path,
    component,
    meta: {
      resourceForm: {
        resourceName,
        formMode: mode,
        slug,
        formSlug,
      },
    },
  };
}

export default financePanel;
