<template>
  <div class="column" data-test-id="output-img">
    <div>Identification Card (8.5cm x 5.5cm) - Front</div>
    <div class="m-2 p-4 identification-card">
      <img
        class="identification-card__card-logo level-item mr-2 my-2"
        src="@/assets/rec_logo_light.svg"
      />
      <div class="has-text-centered">Identification Card</div>
      <div class="level" v-if="primaryImageDirectory">
        <img
          class="identification-card__id-pic level-item has-text-centered"
          align="center"
          :src="imgSRC(primaryImageDirectory)"
          data-test-id="employee-pic"
        />
      </div>

      <h1 class="is-size-4 m-0" align="center" data-test-id="employee-name">
        {{ name }}
      </h1>
      <h1 class="is-size-6" align="center">{{ position }}</h1>
      <h1 class="is-size-7" align="center">
        {{ referenceNumber }}
      </h1>
      <h1
        class="is-size-7"
        align="center"
        data-test-id="expiration-date"
        id="expiration-date"
      >
        Valid up to {{expDate ? expDate : dateOfValidity }}
      </h1>
    </div>
  </div>

  <div class="column">
    <div>Identification Card (8.5cm x 5.5cm) - Back</div>
    <div class="m-2 p-4 identification-card" data-test-id="terms-and-condition">
      <h1 align="center">Terms and Conditions</h1>
      <p class="is-size-7" align="left">
        &#x203A; Identification cards are property of Ronkowski Engineers Co.
      </p>
      <p class="is-size-7" align="left">
        &#x203A; For your safety and to protect your property, lost cards must
        be immediately reported to Headquarters at Ronkowski Engineers Co. C/O
        Great Eastern Resins; Lot 3 & 4; Block 22, Purok Maligaya 2;, Barangay
        Mambugan, Antipolo City, Barangay Mambugan, Antipolo, 1870 Rizal
      </p>
      <p class="is-size-7" align="left">
        &#x203A; The card holder bears cost of replacement on lost and/or
        damaged cards.
      </p>
      <p class="is-size-7" align="left">
        &#x203A; If your original card is found after a replacement card has
        been issued, the original card must be returned to Ronkowski Engineers
        Co.
      </p>
      <p class="is-size-7" align="left">
        &#x203A; For Card Owner, Do not lend your card to anyone for any
        purpose. Doing so is a violation of the Ronkowski Engineers Co. Access
        Control Policy and may lead to judicial charges.
      </p>
      <p class="is-size-7" align="left">
        &#x203A; Any tampering or defacing of the card may lead to legal
        charges.
      </p>

      <div class="has-text-centered">
        <img
          class="identification-card__signature"
          v-if="signatureDirectory"
          :src="imgSRC(signatureDirectory)"
          data-test-id="signature"
        />
        <p class="identification-card__signature-label">
          {{ name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const { DateTime } = require('luxon');
import { ref, toRefs, computed } from 'vue';
import { imgSRC } from '@/global';

export default {
  props: { employee: Object,
  expDate: String },
  setup(props) {
    const { name, position, referenceNumber, signature, primaryImage } = toRefs(
      props.employee
    );
    const signatureDirectory = ref(signature?.value?.storageDirectory);
    const primaryImageDirectory = ref(primaryImage?.value?.storageDirectory);

    const dateOfValidity = computed(() => {
      return DateTime.now()
        .plus({ year: 1 })
        .toLocaleString(DateTime.DATE_FULL);
    });

    return {
      imgSRC,
      name,
      signatureDirectory,
      primaryImageDirectory,
      referenceNumber,
      dateOfValidity,
      position,
    };
  },
};
</script>

<style lang="sass" scoped>
.identification-card
  border: solid thin black
  overflow: hidden
  width: 20em
  height: 30em

  &__card-logo
    padding: 0
    margin: 0
    max-height: 2em
    display: block
    margin-left: 25%

  &__signature
    max-height: 6em
    max-width: 4em

  &__signature-label
    border-top: solid thin black

  &__id-pic
    height: 15em
</style>
