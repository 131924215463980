import { useRoute } from "vue-router";
import _ from "lodash";

const defaultProps = {
  isEnabled: { default: false },
  modelValue: { type: null },
  title: { type: String },
  id: { type: String },
  prefix: { type: String },
};

function generateID(props) {
  let { prefix, title, id } = props;
  let mainDataTestID = _.kebabCase(id || title);
  prefix = _.kebabCase(prefix);
  if (prefix) {
    return `${prefix}-${mainDataTestID}`;
  } else {
    return mainDataTestID;
  }
}

function isDisabled(forceEnable = false) {
  const route = useRoute();
  return forceEnable ? false : route.meta.formMode == "display";
}

function genID(prefix, title, id) {
  let mainDataTestID = _.kebabCase(id || title);
  prefix = _.kebabCase(prefix);
  return prefix ? `${prefix}-${mainDataTestID}` : `${mainDataTestID}`;
}

function initProps(props, route) {
  const { prefix, title, id } = props;
  const elementID = genID(prefix, title, id);
  const isDisplay = route.meta.formMode == "display";
  return { elementID, isDisplay };
}

export { defaultProps, isDisabled, generateID, initProps };
