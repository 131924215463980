<template>
  <nav
    class="navbar is-light no-print"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" :href="homeURL">
        <img src="@/assets/rec_logo_light.svg" width="112" height="28" />
      </a>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>

      <div id="divider" class="navbar-item px-2 has-text-grey-light">|</div>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <a class="navbar-item" href="/#top"> Home </a>
        <router-link class="navbar-item" :to="{ name: 'Brochure' }">
          Brochure
        </router-link>
        <div class="navbar-item has-dropdown is-hoverable">
          <a
            class="navbar-link"
            data-test-id="enterprise"
            v-if="empName && empPosition"
          >
            Enterprise Menu
          </a>
          <div
            id="enterprise-menu-dropdown"
            class="navbar-dropdown"
            @click="
              (event) => {
                event.target.blur();
              }
            "
          >
            <div class="columns px-4 enterprise-sections mb-2">
              <div class="column" align="left">
                <span class="has-text-weight-bold"> General Concerns </span>
                <hr class="my-1" />
                <NavigationBarItem title="Service Offers" />
                <NavigationBarItem title="Companies" />
                <NavigationBarItem title="Measurable Units" />
                <NavigationBarItem title="Authorization Center" />
                <NavigationBarItem title="Audit Report" />
              </div>
              <div class="column" align="left">
                <span class="has-text-weight-bold">
                  Projects and Operations
                </span>
                <hr class="my-1" />
                <NavigationBarItem title="Projects" />
                <NavigationBarItem title="Operations Center" />
                <NavigationBarItem title="Project Vouchers" />
                <NavigationBarItem title="Purchase Orders" />
                <NavigationBarItem title="Subcon Agreements" />
                <NavigationBarItem title="Subcon Payment Slips" />
                <NavigationBarItem title="Client Representatives" />
                <NavigationBarItem title="Catalogues" />
              </div>
              <div class="column" align="left">
                <span class="has-text-weight-bold"> Human Resources </span>
                <hr class="my-1" />
                <NavigationBarItem title="Employees" />
                <NavigationBarItem title="Employee Media" />
                <NavigationBarItem title="Dividend Center" />
              </div>
              <div class="column" align="left">
                <span class="has-text-weight-bold">
                  Accounting and Finance
                </span>
                <hr class="my-1" />
                <NavigationBarItem title="Expense Categories" />
                <NavigationBarItem title="Share Withdrawals" />
                <NavigationBarItem title="Indirect Costs" />
                <NavigationBarItem title="Fund Center" />
                <NavigationBarItem title="Funds" />
                <NavigationBarItem title="Fund Adjustments" />
                <NavigationBarItem title="Dividend Adjustments" />
                <NavigationBarItem title="Client Payments" />
                <NavigationBarItem title="Witholding Taxes" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <span class="material-icons mr-2">chevron_right</span>
          <span class="mr-2" v-if="empName">{{ empName }}</span>
          <span class="mr-2" v-if="empName">|</span>
          <span class="mr-5" v-if="empPosition">{{ empPosition }}</span>
          <div class="buttons">
            <router-link
              v-if="!empName"
              key="registration"
              to="/registration"
              class="button is-success"
              title="Registration"
            >
              <span class="material-icons mr-2">app_registration</span>
              <span>Register</span>
            </router-link>
            <router-link
              v-if="!empName"
              key="Account Login"
              to="/account_login"
              class="button is-success"
              title="Account Login"
            >
              <span class="material-icons mr-2">login</span>
              <span>Sign In</span>
            </router-link>
            <button
              v-if="empName"
              class="button is-danger"
              title="Log Out"
              @click="logout"
            >
              <span class="material-icons">logout</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ResourceService from "@/services/resource_service";
import store from "@/store/index.js";
import { useRouter } from "vue-router";
import { ref, watch, onMounted } from "vue";
import NavigationBarItem from "./NavigationBarItem.vue";

export default {
  emits: ["moduleChanged"],
  components: { NavigationBarItem },
  setup() {
    const router = useRouter();
    const empName = ref(null);
    const empPosition = ref(null);
    const homeURL = process.env.VUE_APP_MAIN_URL;

    onMounted(async () => await fetchUserDetails());
    watch(
      () => store.getters.userId,
      async (newUserID) => await fetchUserDetails(newUserID)
    );

    async function fetchUserDetails(newUserID = store.getters.userId) {
      if (newUserID) {
        const employeeService = new ResourceService("employees");
        employeeService
          .read(newUserID)
          .then((employee) => {
            const { position, name } = employee;
            empName.value = name;
            empPosition.value = position;
          })
          .catch((err) => {
            if (err == "Resource Not Found") err = "Invalid User Logged In";
            store.commit("noteError", err);
            logout();
          });
      }
    }

    function logout() {
      empName.value = null;
      empPosition.value = null;
      store.dispatch("setUserId", null);
      store.dispatch("setUserToken", null);
      localStorage.removeItem("userId");
      localStorage.removeItem("userToken");
      router.push({ name: "Home" });
      store.commit("noteSuccess", "Logout Successful");
    }

    function closeMenu() {
      document.getElementById("divider").click();
    }

    return {
      empName,
      empPosition,
      logout,
      closeMenu,
      homeURL,
    };
  },
};
</script>

<style lang="sass" scoped>
.enterprise-menu-dropdown
  z-index: 25
.enterprise-sections
  .column
    width: 18em

@media print
  .no-print, .no-print *
    display: none !important
</style>
