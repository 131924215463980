<template>
  <section class="hero print-container">
    <div class="container">
      <div class="columns">
        <div class="column is-half print-content">
          <img src="@/assets/rec_logo_light.svg" class="title" />
          <p class="has-text-left content-desktop">
            Ronkowski Engineers Co. (REC) was founded in order to offer
            businesses Lean Style and Proactive Building Maintenance Design and
            Services. At our enterprise, we focus on reliability centered
            maintenance; wherein objectives are directed by maintaining
            machinery or asset effectiveness; and empowering our teams with
            multi-skilled maintenance technicians and engineers who adhere to
            the value of 5S and Kaizen Improvement Principles.
          </p>
        </div>
        <div class="column is-half">
          <img src="@/assets/team.svg" class="plumber" />
          <p class="has-text-left content-mobile">
            Ronkowski Engineers Co. (REC) was founded in order to offer
            businesses Lean Style and Proactive Building Maintenance Design and
            Services. At our enterprise, we focus on reliability centered
            maintenance; wherein objectives are directed by maintaining
            machinery or asset effectiveness; and empowering our teams with
            multi-skilled maintenance technicians and engineers who adhere to
            the value of 5S and Kaizen Improvement Principles.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="sass" scoped>
@media print
  .print-container
    width: 100vh
    margin-top:-10rem
    height: 50vh
    page-break-after: always
  .print-content
   margin-top:5rem


.title
  margin-top:15rem
  width: 20rem
  margin-right:21rem

.plumber
  height:100vh
  margin-top: -1rem
.content-mobile
  display: none
.content-desktop
  width: 30rem


@media (max-width: 1023px)
@media (max-width: 768px)
  .title
   margin: 0
   width: 100%
   padding: 2rem
  .plumber
   margin-top: -15rem
  .content-mobile
    display: block
    padding: 2rem
    margin-top: -15rem
  .content-desktop
    display: none !important
</style>
