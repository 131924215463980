import generateDefaults from "./defaults.js";

export default generateDefaults({
  state: {
    total: 0,
    resultCount: 0,
    proposals: 0,
    wips: 0,
    finished: 0,
    amendmentID: null,
    progressID: null,
  },
  getters: {},
  mutations: {
    setupStats(state, stats) {
      state.total = stats.total;
      state.resultCount = stats.resultCount;
      state.proposals = stats.proposals;
      state.wips = stats.wips;
      state.finished = stats.finished;
    },
  },
  actions: {},
});
