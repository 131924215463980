<template>
  <section class="section print-container">
    <h1 class="has-text-left is-size-1 header-service">Services Offered</h1>
    <div class="container">
      <div class="columns">
        <div class="column is-half">
          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">power</div>
              <div>Electrical Systems</div>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">air</div>
              <div>HVAC Systems</div>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">control_camera</div>
              <div>Testing and Commissioning</div>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">fire_extinguisher</div>
              <div>Fire Protection Systems</div>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">water_damage</div>
              <div>Plumbing Systems</div>
            </div>
          </div>

          <div class="card">
            <div class="card-content">
              <div class="card-logo material-icons">design_services</div>
              <div>HVAC/MEPF Systems Design</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="sass" scoped>
.card
  margin-bottom:1rem
  height: 10rem
.card-logo
  font-size:5rem

.header-service
  margin-bottom: 3rem
  color:#F7931E

section
  height: 100vh

@media print
  .print-container
    width:55vh
    height:80vh
    margin-top:9rem

@media (max-width: 1023px)

@media (max-width: 768px)
</style>
