import { singular } from "pluralize";
import ResourceService from "@/services/resource_service";
import ResourceRouter from "./resource_router";
import resourceStore from "@/store/resource.js";
import store from "@/store/index.js";
import { pascalCase } from "change-case";

class SheetRouter extends ResourceRouter {
  static idForms = ["edit", "display", "history", "bin"];

  constructor(title) {
    super(title);
    this.headerList = null;
    this.displaySlug = null;
  }

  setHeaders(headerList) {
    this.headerList = headerList;
    return this;
  }

  buildRoute() {
    const { title, subtitle, slug, logo, resourceName, headerList } = this;
    const formSlug = `${pascalCase(resourceName)}`;
    SheetRouter.routes.push({
      name: title,
      path: `/enterprise/${slug}`,
      component: this.componentFxn,
      meta: {
        formMode: null,
        layout: "sheet",
        title,
        subtitle,
        slug,
        logo,
        resourceName,
        headerList,
        formSlug,
      },
      children: [
        {
          path: ":id",
          name: `Display ${resourceName}`,
          component: this.componentFxn,
          meta: {
            formMode: "display",
            formSlug: this.displaySlug || formSlug,
            documentKey: this.documentKey,
          },
          children: [
            {
              path: "edit",
              name: `Edit ${resourceName}`,
              component: this.componentFxn,
              meta: { formMode: "edit", formSlug },
            },
            {
              path: "history",
              name: `History of ${resourceName}`,
              component: this.componentFxn,
              meta: { formMode: "history", formSlug },
            },
          ],
        },
        {
          path: "new",
          name: `New ${resourceName}`,
          component: this.componentFxn,
          meta: { formMode: "new" },
        },
        {
          path: "bin",
          name: `Deleted ${title}`,
          component: this.componentFxn,
          meta: { formMode: "bin" },
        },
      ],
    });
  }

  setDisplaySlug(documentKey = "editorDocID", fileSlug = "CloudDocViewer") {
    this.documentKey = documentKey;
    this.displaySlug = `doc_viewers/${fileSlug}`;
    return this;
  }

  static setStates(formMode, { meta, params }) {
    const { title, slug } = meta;
    const modelName = singular(title);
    if (!store.hasModule("resource")) {
      store.registerModule("resource", resourceStore);
    }
    store.dispatch("setResourceId", null);
    store.dispatch("setResourceName", modelName);
    store.dispatch("setResourceService", new ResourceService(slug));
    if (formMode == "new") {
      store.dispatch("setResourceId", null);
    } else if (this.idForms.includes(formMode)) {
      store.dispatch("setResourceId", params.id);
    } else {
      store.dispatch("setResourceId", null);
    }
  }

  componentFxn = () => {
    return import(
      /* webpackChunkName: "Sheet" */
      "@/views/sheet/Sheet.vue"
    );
  };
}

export default SheetRouter;
