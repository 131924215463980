<template>
  <section class="section print-mission">
    <div class="columns">
      <div class="column is-half mision">
        <h1 class="has-text-left content is-size-1 header">Mission</h1>
        <p class="has-text-left mision-content">
          At Ronkowski Engineers Co., it is our mission to provide the highest
          level of quality services at fair and competitive market prices, and
          maintain the highest level of professionalism, honesty, and fairness
          in our relationships with our customers, employees, and vendors. To
          grow by continuously providing the highest quality service, and
          solutions to markets we already serve – and to expand into new areas
          that build on our competencies and customer interests.
        </p>
      </div>
      <div class="column is-half no-print mision-img">
        <img src="@/assets/plumber-running.svg" class="plumber" />
      </div>
    </div>
  </section>

  <section class="section print-vission">
    <div class="columns">
      <div class="column is-half no-print vision-img">
        <img src="@/assets/plumber.svg" class="plumber" />
      </div>
      <div class="column is-half vision">
        <h1 class="has-text-left content is-size-1 vision-header">Vission</h1>
        <p class="has-text-left vision-content">
          To be the preferred contractor of choice. A company that our customers
          want to work with and our employees are proud to work for.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="sass" scoped>
.header
  color:#F7931E
.vision-header
  color:#F7931E

.plumber
  height:100vh
  margin-top: -20rem

.content
  width: 30rem

section
  height: 100vh


@media print
  .no-print, .no-print *
    display: none !important
  .print-mission
   height: 50vh
   float: right
   margin-top: -49rem
   margin-right: -3rem

  .print-vission
   height: 30vh
   margin-top: -29rem
   float: right
   margin-right: -3rem

@media (max-width: 1023px)

@media (max-width: 768px)
  section
    margin-top: 30rem
  .mision
    width: 100%
  .mision-content
    text-align: center
  .mision-img
    margin-top:7rem
  .vision
   margin-top:-60rem
  .vision-img
    margin-top:-10rem
  .vision-content
    text-align: center
  .vision-header
   margin-top:12rem
   color:#F7931E
</style>
