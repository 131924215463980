<template>
  <div class="select">
    <select
      v-bind="$attrs"
      v-model="fieldValue"
      :disabled="isDisabled(isEnabled)"
      :data-test-id="uniqueID"
    >
      <option disabled="disabled" :value="null" selected="true">
        {{ title }}
      </option>
      <option
        v-for="choice in choices"
        :key="choice.value"
        :value="choice.value"
      >
        {{ choice.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { defaultProps } from "./concerns.js";
import InputHandler from "./input_handler.js";

export default {
  props: {
    ...defaultProps,
    placeholder: { type: String },
    choices: { type: Array },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const inputHandler = new InputHandler(props, emit);
    return { ...inputHandler.exec() };
  },
};
</script>

<style></style>
