<template>
  <div class="modal" :class="status ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-content">
      <section class="modal-card-head">
        <p class="modal-card-title">New {{ title }}</p>
        <button class="delete" aria-label="close" @click="closePopup()" />
      </section>
      <form @submit.prevent="submitForm">
        <section class="modal-card-body">
          <component formType="popup" v-model="formData" :is="formFile" />
        </section>
        <footer
          class="modal-card-foot is-justify-content-center"
          align="center"
        >
          <button
            type="submit"
            class="button is-success"
            :data-test-id="dataTestId"
          >
            Create a New {{ title }}
          </button>
        </footer>
      </form>
    </div>
    <button class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
import ResourceService from "@/services/resource_service.js";
import { defineAsyncComponent, computed, ref } from "vue";
import { pascalCase, snakeCase } from "change-case";
import { useStore } from "vuex";
import pluralize from "pluralize";

export default {
  props: {
    title: { type: String, required: true },
    filename: { type: String, default: (props) => pascalCase(props.title) },
    slug: {
      type: String,
      default: (props) => snakeCase(pluralize(props.title)),
    },
    status: { type: Boolean, default: false },
    modelValue: { type: Object },
    initData: { type: Object },
    dataTestId: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const formData = ref(props.initData);
    const store = useStore();

    function submitForm() {
      const service = new ResourceService(props.slug);
      service
        .create(formData.value)
        .then((data) => {
          emit("submitted", data);
          emit("closePopup", true);
        })
        .catch((err) => {
          store.commit("noteError", err);
        });
    }

    function closePopup() {
      formData.value = {};
      emit("closePopup", true);
    }

    const formFile = computed(() => {
      return defineAsyncComponent(() => {
        return import(`@/components/resource_forms/${props.filename}.vue`);
      });
    });

    return {
      formData,
      submitForm,
      closePopup,
      formFile,
    };
  },
};
</script>

<style lang="sass" scoped>
div.modal
  .modal-card
    border-radius: 0
  .modal-content
    width: auto

.modal-card-head, .modal-card-foot
  border-radius: 0
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
</style>
