const axios = require("axios");
import store from "@/store/index.js";
import { DEF_REQ_TIMEOUT } from "@/global.js";

class MainService {
  headerOptions = { "Content-Type": "multipart/form-data" };
  constructor(slug, serviceSlug) {
    const userToken =
      store.getters.userToken || localStorage.getItem("userToken");
    this.slug = slug;
    this.axios = axios.create({
      baseURL: `${process.env.VUE_APP_BACKEND_HOST}/${serviceSlug}/`,
      timeout: parseInt(DEF_REQ_TIMEOUT),
    });
    this.axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
    this.parseError = parseError;
    return this;
  }
}

function parseError(errObj) {
  console.error(errObj);
  const status = errObj?.response?.status;
  if (status == 500) {
    throw "Internal Server Error";
  } else if (status == 404) {
    throw "Resource Not Found";
  } else if (status == 403) {
    throw "Unauthorized Access";
  } else if (status == 400) {
    throw "Invalid Request / Validation Failed";
  } else {
    console.log("main_service.js");
    throw `General Error Status Code : ${
      !status ? "Backend Server is Down!" : status
    }`;
  }
}

export default MainService;
