<template>
  <div class="container">
    <div class="level mt-4">
      <ModuleTitle />
      <div class="level-right">
        <BaseSelect
          title="Status"
          class="level-item"
          v-model="fields.status"
          :choices="statusOptions"
        />
        <BaseInput
          class="level-item mt-1"
          title="Reference Number"
          v-model="fields.referenceNumber"
        />
      </div>
    </div>
    <table
      class="table is-narrow is-hoverable is-striped"
      align="center"
      :key="refreshFlag"
    >
      <thead>
        <tr align="left">
          <th>REFNO</th>
          <th>Status</th>
          <th>Public?</th>
          <th>Description</th>
          <th>Contract Price</th>
          <th>Taxable</th>
          <th>Billed AMT</th>
          <th>Collected AMT</th>
          <th>Profit / Loss</th>
        </tr>
      </thead>
      <tbody align="left">
        <tr v-for="project in projects" :key="project">
          <td>{{ project.referenceNumber }}</td>
          <td>{{ project.status }}</td>
          <td>{{ project.primaryImage ? `YES` : `NO` }}</td>
          <td>{{ project.description }}</td>
          <td align="right" class="num-col">
            {{ formatMoney(project.grandTotal) }}
          </td>
          <td align="right">{{ project.taxable ? `YES` : `NO` }}</td>
          <td align="right" class="num-col">
            {{ formatMoney(project.billedAMT) }}
          </td>
          <td align="right" class="num-col">
            {{ formatMoney(project.collectedAMT) }}
          </td>
          <td align="right" class="num-col">
            {{ formatMoney(project.profit) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ResourceService from "@/services/resource_service.js";
import ModuleTitle from "@/components/ModuleTitle.vue";
import BaseSelect from "@/components/base_fields/BaseSelect.vue";
import BaseInput from "@/components/base_fields/BaseInput.vue";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { accounting } from "@/global.js";
export default {
  components: { ModuleTitle, BaseSelect, BaseInput },
  setup() {
    const { formatMoney } = accounting;
    const route = useRoute();
    const router = useRouter();
    const { reference_number, status } = route.query;
    const projectIDS = ref([]);
    const projects = ref([]);
    const fields = ref({ referenceNumber: reference_number, status });
    const refreshFlag = ref(Date.now());
    const projSvc = new ResourceService("projects");
    const statusOptions = [
      { label: `PROPOSAL`, value: `PROPOSAL` },
      { label: `WORK-IN-PROGRESS`, value: `WORK-IN-PROGRESS` },
      { label: `FINISHED`, value: `FINISHED` },
    ];

    onMounted(async () => await refreshTable(fields.value));

    watch(fields, refreshTable, { deep: true });

    async function refreshTable(newFields) {
      projects.value = [];
      const ixParams = newFields || fields.value;
      projectIDS.value = await projSvc.index({ mode: "ids", ...ixParams });
      for await (const id of projectIDS.value) {
        projects.value.push(await projSvc.read(id, { mode: "op-center" }));
      }
      router.push({
        query: {
          reference_number: newFields.referenceNumber,
          status: newFields.status,
        },
      });
    }
    return { refreshFlag, fields, projects, statusOptions, formatMoney };
  },
};
</script>

<style lang="sass" scoped>
.num-col
  white-space: nowrap
</style>
